export const realestateConstruction = {
    problem: {
        label: 'Pouvez-vous décrire brièvement la nature du problème?',
    },
    damageValue: {
        label: 'Quelle est la valeur des dommages (montant que vous réclamez ou que l’on vous réclame)?',
    },
    formalNotice: {
        label: 'Avez-vous reçu ou transmis un avis / mise en demeure ou procédure dans ce dossier?',
        fieldType: 'yesNoDontKnow',
    },
    expert: {
        label: 'Avez-vous mandaté un expert ou autre entrepreneur pour le dossier (évaluateur, entrepreneur, ingénieur, inspecteur etc.)?',
        fieldType: 'yesNo',
        autoCheckCondition: (val) => val === 'yes',
    },
    amicable: {
        label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
        fieldType: 'yesNoDontKnow',
    },
}

export const realestateCondo = {
    condoType: {
        label: 'Est-ce qu’il s’agit d’une copropriété divise ou indivise?',
        fieldType: 'buttonRow',
        options: [
            {
                label: 'Divise',
                value: 'divise',
            },
            {
                label: 'Indivise',
                value: 'indivise',
            },
        ],
    },
    ownersNumber: {
        label: 'Combien de copropriétaire y-a-t-il dans l’immeuble?',
    },
    syndicate: {
        label: 'Est-ce que vous faites partie du syndicat de copropriété/conseil d’administration de la copropriété?',
        fieldType: 'yesNo',
    },
    unitValue: {
        label: 'Quelle est la valeur de votre unité?',
        fieldType: 'number',
    },
    job: {
        label: 'Quel est votre occupation ou emploi?',
    },
    formalNotice: {
        label: 'Avez-vous reçu ou transmis un avis / mise en demeure ou procédure dans ce dossier?',
        fieldType: 'yesNoDontKnow',
    },
}

export const realestateVice = {
    label1: {
        fieldType: 'label',
        label: 'Votre vice-caché',
    },
    buySell: {
        label: 'Acheteur ou vendeur?',
        fieldType: 'buttonRow',
        options: [
            {
                value: 'buyer',
                label: 'Acheteur',
            },
            {
                value: 'seller',
                label: 'Vendeur',
            },
        ],
        preFormOnly: true,
        autoCheck: true,
    },
    propertyType: {
        label: 'Le bien visé correspond à quelle catégorie',
        fieldType: 'select',
        options: [
            {
                name: 'realestate',
                label: 'Immobilier (terrain, bâtisse, chalet, maison mobile)',
            },
            {
                name: 'mobile',
                label: 'Mobilier (bâteau, roulotte, VR, voiture, moto, équipements, meubles divers, etc)',
            },
            {
                name: 'other',
                label: 'Autre/Je ne sais pas',
            },
        ],
        preFormOnly: true,
        autoCheckCondition: (val) => val === 'realesate',
    },
    otherPropertyType: {
        label: 'Préciser le bien',
        dependsOn: {
            fieldName: 'propertyType',
            toBe: ['mobile', 'other'],
        },
        preFormOnly: true,
    },
    buyerWants: {
        label: 'Vous souhaitez',
        fieldType: 'select',
        options: [
            {
                name: 'cancel',
                label: 'L’annulation de la vente',
            },
            {
                name: 'reduce',
                label: 'La réduction du prix de vente (dédommagement pour les travaux de réparation)',
            },
            {
                name: 'other',
                label: 'Autre / Je ne sais pas',
            },
        ],
        dependsOn: {
            fieldName: 'buySell',
            toBe: 'buyer',
        },
        preFormOnly: true,
    },
    sellerWants: {
        label: 'L’acheteur recherche',
        fieldType: 'select',
        options: [
            {
                name: 'cancel',
                label: 'L’annulation de la vente',
            },
            {
                name: 'reduce',
                label: 'La réduction du prix de vente (dédommagement pour les travaux de réparation)',
            },
            {
                name: 'other',
                label: 'Autre / Je ne sais pas',
            },
        ],
        dependsOn: {
            fieldName: 'buySell',
            toBe: 'seller',
        },
        preFormOnly: true,
    },
    otherWants: {
        label: 'Précisez ce que vous recherchez',
        dependsOnOne: [
            {
                fieldName: 'sellerWants',
                toBe: 'other',
            },
            {
                fieldName: 'buyerWants',
                toBe: 'other',
            },
        ],
        preFormOnly: true,
    },
    letter: {
        label: 'Avez-vous reçu ou transmis un avis de connaissance du vice, mise en demeure ou procédure dans ce dossier?',
        fieldType: 'yesNoDontKnow',
        postFormOnly: true,
        autoCheck: true,
    },
    value: {
        label: 'Quelle est la valeur du vice (montant que vous réclamez ou que l’on vous réclame)?',
        postFormOnly: true,
    },
    expert: {
        label: 'Avez-vous mandaté un expert pour le dossier (évaluateur, entrepreneur, ingénieur, inspecteur etc)',
        fieldType: 'yesNo',
        postFormOnly: true,
        autoCheck: true,
    },
    otherExpert: {
        label: `Précisez le type d'expert mandaté`,
        dependsOn: {
            fieldName: 'expert',
            toBe: 'yes',
        },
        postFormOnly: true,
    },
    amicable: {
        label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
        fieldType: 'yesNoDontKnow',
        postFormOnly: true,
    },
    otherDetails: {
        label: 'Souhaitez-vous nous fournir d’autres détails que vous jugez importants?',
        postFormOnly: true,
    },
}

export const realestateTransaction = {
    label1: {
        label: 'Tell us about your transaction',
        preFormOnly: true,
        fieldType: 'label',
    },
    transactionType: {
        label: 'What kind of real-estate transaction are you looking for?',
        fieldType: 'select',
        options: [
            {
                name: 'buyer',
                label: 'Buying',
            },
            {
                name: 'seller',
                label: 'Selling',
            },
            {
                name: 'ownerRefinancing',
                label: 'Owner Refinancing',
            },
            {
                name: 'mortageFinancing',
                label: 'Someone looking for mortgage financing',
            },
            {
                name: 'other',
                label: 'Other',
            },
        ],
        preFormOnly: true,
        autoCheckCondition: (val) => val === 'buyer' || val === 'seller',
    },
    otherTransactionType: {
        label: 'Specify',
        dependsOn: {
            fieldName: 'transactionType',
            toBe: 'other',
        },
        preFormOnly: true,
    },
    numProperties: {
        label: 'How many properties are involved in this transaction?',
        fieldType: 'select',
        options: [
            {
                name: '1',
                label: '1',
            },
            {
                name: '2',
                label: '2',
            },
            {
                name: '3',
                label: '3',
            },
            {
                name: '4+',
                label: '4 or more',
            },
        ],
        preFormOnly: true,
    },
    propertyType: {
        label: 'What is/are the type of property(ies)?',
        fieldType: 'checkBoxes',
        options: [
            {
                name: 'detached-freehold',
                label: 'Detached or Freehold',
            },
            {
                name: 'condo',
                label: 'Condo',
            },
            {
                name: 'semi-detached',
                label: 'Semi-detached',
            },
            {
                name: 'mixed',
                label: 'Mixed',
            },
        ],
        preFormOnly: true,
    },
    specifyMixed: {
        label: 'Specify, mixed',
        dependsOn: {
            fieldName: 'propertyType',
            toBe: 'mixed',
        },
        preFormOnly: true,
    },

    propertyValue: {
        label: 'What is the value of the property or properties',
        preFormOnly: true,
    },
    mortgageCommitment: {
        label: 'Do you have a mortgage commitment from a financial lender?',
        fieldType: 'yesNo',
        preFormOnly: true,
    },
    mortgageAmount: {
        label: 'What is the amount of the mortgage?',
        dependsOn: {
            fieldName: 'mortgageCommitment',
            toBe: 'yes',
        },
        preFormOnly: true,
    },
    agreement: {
        label: 'If this is a purchase or a sale, did you sign an Agreement of purchase/sale?',
        fieldType: 'yesNo',
        preFormOnly: true,
    },
    closingDate: {
        label: 'What is the closing date?',
        fieldType: 'date',
        preFormOnly: true,
    },
    otherDetails: {
        label: 'Do you have any other details you would like to share?',
        preFormOnly: true,
    },
}
