import React, { Fragment, useRef, useEffect } from 'react'
//import Checkbox from '../Checkbox'
import ValidityWarning from '../ValidityWarning'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styled from '@emotion/styled'
import { withTranslation, Trans } from 'gatsby-plugin-react-i18next'
const H3 = styled.h3`
    margin: 0 0.5rem;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
    flex-wrap: wrap;
    margin-bottom: -1rem;
`

const PossibleInderteminateCheckbox = ({
    checkbox,
    handleChange,
    checkStatus,
}) => {
    const checkBoxRef = useRef(null)
    useEffect(() => {
        if (checkStatus === 'some') {
            checkBoxRef.current.indeterminate = true
        } else {
            checkBoxRef.current.indeterminate = false
        }
    }, [checkBoxRef, checkStatus])
    return (
        <div className="form-control">
            <label className="label cursor-pointer">
                <input
                    type="checkbox"
                    name={checkbox.name}
                    onClick={(evt) =>
                        handleChange(evt, checkbox.children, checkStatus)
                    }
                    checked={
                        checkStatus !== 'none' && checkStatus !== 'some'
                            ? true
                            : false
                    }
                    ref={checkBoxRef}
                    className="checkbox"
                />
                <span className="label-text ml-2 font-black text-2xl">
                    <Trans>{checkbox.label}</Trans>
                </span>
            </label>
        </div>
    )
}

class CheckboxGroup extends React.Component {
    constructor(props) {
        super(props)
        const checked = {}
        const secondaryChecked = {}

        if (typeof props.initialChecked === 'object') {
            props.initialChecked?.forEach((spec) => {
                checked[spec] = true
            })
        }
        if (typeof props.secondaryInitialChecked === 'object') {
            props.secondaryInitialChecked?.forEach((sChecked) => {
                secondaryChecked[sChecked] = true
            })
        }
        this.state = {
            checked,
            secondaryChecked,
            valid: props.valid,
        }
    }

    checkedArr = (checkedObj, name) =>
        Object.keys(checkedObj).filter((key) => {
            return checkedObj[key] === true
        })

    onChange = (evt) => {
        const { name } = evt.target
        const checked = {
            ...this.state.checked,
            [name]: !this.state.checked[name],
        }
        const checkedArr = this.checkedArr(checked, name)

        this.props.updateState(this.props.name, checkedArr)
        this.setState({
            checked,
            valid: checkedArr.length > 0,
        })
    }

    handleParentCheckBox = (evt, children, status) => {
        evt.stopPropagation()
        const { name } = evt.target
        const statusToApply =
            status === 'all' || status === 'some' ? false : true
        const newChecks = {}
        for (let i = 0; i < children.length; i++) {
            if (this.props.lawyerOrNotary !== 'paralegal' || !statusToApply) {
                newChecks[children[i].name] = statusToApply
            } else if (children[i].paralegal) {
                newChecks[children[i].name] = statusToApply
            }
        }
        const checked = {
            ...this.state.checked,
            ...newChecks,
        }

        const checkedArr = this.checkedArr(checked, name)
        this.props.updateState(this.props.name, checkedArr)
        this.setState({
            checked,
            valid: checkedArr.length > 0,
        })
    }

    onSecondaryChange = (evt) => {
        evt.stopPropagation()
        const { name } = evt.target
        const secondaryChecked = {
            ...this.state.secondaryChecked,
            [name]: !this.state.secondaryChecked[name],
        }
        const checkedArr = this.checkedArr(secondaryChecked, name)
        this.props.updateState(this.props.secondaryName, checkedArr)
        this.setState({ secondaryChecked })
    }

    parentCheckStatus = (children) => {
        let numChecked = 0
        let possibleChecked = 0
        for (let i = 0; i < children.length; i++) {
            if (this.state.checked[children[i].name] && !children[i].disabled) {
                numChecked++
            }
            if (!children[i].disabled) {
                if (this.props.lawyerOrNotary === 'paralegal') {
                    if (children[i].paralegal) {
                        possibleChecked++
                    }
                } else {
                    possibleChecked++
                }
            }
        }
        if (numChecked === 0) {
            return 'none'
        }
        return numChecked === possibleChecked ? 'all' : 'some'
    }

    renderCheckBoxes = (checkboxes) => {
        return checkboxes.map((checkbox) => {
            if (checkbox.disabled) {
                return null
            }

            if (checkbox.children && !this.props.onlyParents) {
                if (this.props.lawyerOrNotary === 'paralegal') {
                    let paralegalAvailable = false
                    checkbox.children?.forEach((c) => {
                        if (c.paralegal) {
                            paralegalAvailable = true
                        }
                    })
                    if (!paralegalAvailable) {
                        return null
                    }
                }
                const checkStatus = this.parentCheckStatus(checkbox.children)
                return (
                    <Accordion
                        key={checkbox.name}
                        style={{ maxWidth: '100%', width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand">
                            <PossibleInderteminateCheckbox
                                checkbox={checkbox}
                                checkStatus={checkStatus}
                                handleChange={this.handleParentCheckBox}
                            />

                            {checkbox.certRequired &&
                            (checkStatus === 'some' ||
                                checkStatus !== 'none') ? (
                                <div
                                    onClick={
                                        (evt) =>
                                            evt.stopPropagation() /* This stops the accordion to toggle */
                                    }>
                                    <div className="form-control">
                                        <label className="label cursor-pointer">
                                            <input
                                                name={checkbox.name}
                                                type="checkbox"
                                                checked={
                                                    this.state.secondaryChecked[
                                                        checkbox.name
                                                    ]
                                                        ? true
                                                        : false
                                                }
                                                onChange={
                                                    this.onSecondaryChange
                                                }
                                                className="checkbox"
                                            />
                                            <span className="label-text ml-2">
                                                <Trans i18nKey="legalAidCert">
                                                    Je suis sur le panel d’aide
                                                    juridique
                                                </Trans>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            ) : null}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                {this.renderCheckBoxes(checkbox.children)}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )
            } else {
                if (
                    this.props.lawyerOrNotary === 'paralegal' &&
                    !checkbox.paralegal
                ) {
                    return null
                }
                if (checkbox.topLevel && !this.props.onlyParents) {
                    return (
                        <AccordionSummary key={checkbox.name}>
                            <div className="form-control">
                                <label className="label cursor-pointer">
                                    <input
                                        type="checkbox"
                                        name={checkbox.name}
                                        onChange={this.onChange}
                                        checked={
                                            this.state.checked[checkbox.name] ||
                                            false
                                        }
                                        className="checkbox"
                                    />
                                    <span className="label-text ml-2 font-black text-2xl">
                                        <Trans>{checkbox.label}</Trans>
                                    </span>
                                </label>
                            </div>
                            {checkbox.certRequired ? (
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <input
                                            name={checkbox.name}
                                            type="checkbox"
                                            checked={
                                                this.state.secondaryChecked[
                                                    checkbox.name
                                                ]
                                                    ? true
                                                    : false
                                            }
                                            onChange={this.onSecondaryChange}
                                            className="checkbox"
                                        />
                                        <span className="label-text ml-2">
                                            <Trans i18nKey="legalAidCert">
                                                Je suis sur le panel d’aide
                                                juridique
                                            </Trans>
                                        </span>
                                    </label>
                                </div>
                            ) : null}
                        </AccordionSummary>
                    )
                }
                return (
                    <Grid item xs={12} sm={4} key={checkbox.name}>
                        <div className="form-control">
                            <label className="label cursor-pointer justify-start">
                                <input
                                    type="checkbox"
                                    name={checkbox.name}
                                    onChange={this.onChange}
                                    checked={
                                        this.state.checked[checkbox.name] ||
                                        false
                                    }
                                    className="checkbox"
                                />
                                <span className="label-text ml-2">
                                    <Trans>{checkbox.label}</Trans>
                                </span>
                            </label>
                        </div>
                        {checkbox.certRequired &&
                        this.state.checked[checkbox.name] ? (
                            <div className="form-control">
                                <label className="label cursor-pointer">
                                    <input
                                        name={checkbox.name}
                                        type="checkbox"
                                        checked={
                                            this.state.secondaryChecked[
                                                checkbox.name
                                            ]
                                                ? true
                                                : false
                                        }
                                        onChange={this.onSecondaryChange}
                                        className="checkbox"
                                    />
                                    <span className="label-text ml-2">
                                        <Trans i18nKey="legalAidCert">
                                            Je suis sur le panel d’aide
                                            juridique
                                        </Trans>
                                    </span>
                                </label>
                            </div>
                        ) : null}
                    </Grid>
                )
            }
        })
    }

    render() {
        return (
            <div>
                {!this.state.valid && (
                    <ValidityWarning
                        validityMessage={<Trans>{this.props.helperText}</Trans>}
                    />
                )}
                <Wrapper>
                    {this.renderCheckBoxes(this.props.checkboxes)}
                </Wrapper>
            </div>
        )
    }
}

export default CheckboxGroup
