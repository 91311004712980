import revenueRanges, {
    revenueRangesOrderedKeys,
} from '../../../constants/revenueRanges'
import {
    realestateVice,
    realestateTransaction,
    realestateConstruction,
    realestateCondo,
} from './realestate'
import { housingLandlord, housingTenant } from './housing'

export const englishAvailable = {
    family: true,
    immigration: true,
    criminal: true,
    work: true,
}

export const specificSteps = {
    'crim-transport': [
        ['impairedBy'],
        ['hasCourtDate', 'courtDate', 'courtDateCity'],
        ['hasCriminalFile', 'multipleInfractions'],
        ['accident', 'accidentInjuries', 'accidentDeaths', 'moreDetails'],
    ],
    'family-family': [['label1', 'kids', 'judicialized', 'amicable']],
    family: [
        [
            'details',
            'judicialized',
            'objective',
            'otherObjective',
            'caseType',
            'otherType',
            'spouseRevenueRange',
        ],
        [
            'details',
            'includesRealEstate',
            'investments',
            'business',
            'assets',
            'kids',
            'custodyScenario',
            'pensionScenario',
            'pensionAmount',
            'courtDate',
        ],
        ['othersInvolved', 'caseDescription'],
    ],
    'realestate-vice': [
        [
            'label1',
            'buySell',
            'propertyType',
            'otherPropertyType',
            'sellerWants',
            'buyerWants',
            'otherWants',
        ],
    ],
    'work-termination': [
        [
            'label1',
            'employeeType',
            'howLong',
            'jobType',
            'management',
            'union',
            'employerName',
            'howLongEmployer',
            'jobTypeEmployer',
            'managementEmployer',
            'unionEmployer',
            'numEmployees',
            'activitySector',
        ],
    ],
    'corp-start': [
        [
            'label1',
            'activitySector',
            'job',
            'otherShareHolders',
            'otherShareHoldersAmount',
            'documentsRequired',
        ],
    ],
    'realestate-transaction': [
        [
            'label1',
            'transactionType',
            'otherTransactionType',
            'numProperties',
            'propertyType',
            'specifyMixed',
        ],
        [
            'label1',
            'propertyValue',
            'mortgageCommitment',
            'mortgageAmount',
            'agreement',
            'closingDate',
            'otherDetails',
        ],
    ],
    avocatsPME: [['label1', 'businessName', 'job']],
}
export default {
    admin: {
        procedureStarted: {
            label: `Procédures instituées auprès d'un tribunal?`,
            fieldType: 'yesNoDontKnow',
        },
        procedureStartedDt: {
            adminLabel: `Date d'audience`,
            label: 'Avez-vous une date d’audience?',
            fieldType: 'date',
            dependsOn: {
                fieldName: 'procedureStarted',
                toBe: 'yes',
            },
        },
        injuries: {
            label: 'Est-ce que votre dossier implique une ou des blessures corporelles?',
            adminLabel: `Blessures corporelles?`,
            fieldType: 'yesNoDontKnow',
        },
        injuriesDesc: {
            adminLabel: `Descriptions des blessures`,
            label: 'Pouvez-vous décrire la ou les blessures?',
            dependsOn: {
                fieldName: 'injuries',
                toBe: 'yes',
            },
        },
        employmentPeril: {
            label: 'Est-ce que cette blessure vous empêche ou vous empêchera de poursuivre votre emploi?',
            adminLabel: 'Emploi en péril?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'injuries',
                toBe: 'yes',
            },
        },
        job: {
            label: 'Quel est ou était votre emploi?',
            dependsOn: {
                fieldName: 'injuries',
                toBe: 'yes',
            },
            adminLabel: 'Emploi',
        },
    },
    'realestate-vice': realestateVice,
    'realestate-transaction': realestateTransaction,
    'corp-start': {
        label1: {
            label: 'Votre Incorporation',
            fieldType: 'label',
        },
        activitySector: {
            label: 'Quel est le secteur d’activité de votre entreprise?',
            adminLabel: `Secteur d'activité`,
            preFormOnly: true,
        },
        job: {
            label: 'Quelle est votre fonction au sein de l’entreprise?',
            preFormOnly: true,
        },
        otherShareHolders: {
            label: 'Avez-vous des partenaires qui seront également actionnaires/associés?',
            fieldType: 'yesNoDontKnow',
            preFormOnly: true,
        },
        otherShareHoldersAmount: {
            label: `Précisez combien`,
            dependsOn: {
                fieldName: 'otherShareHolders',
                toBe: 'yes',
            },
            preFormOnly: true,
        },
        documentsRequired: {
            label: 'Pensez-vous avoir besoin d’une convention d’actionnaires ou autres contrats de démarrage?',
            fieldType: 'yesNoDontKnow',
            preFormOnly: true,
        },
        turnover: {
            label: 'Quel est le chiffre d’affaires visé pour votre première année d’existence?',
            postFormOnly: true,
        },
        financing: {
            label: 'Pensez-vous avoir besoin de financement aux fins de votre démarrage, si oui combien?',
            fieldType: 'select',
            options: [
                {
                    name: 'no',
                    label: 'Non',
                },
                {
                    name: 'dontKnow',
                    label: 'Je ne sais pas / préfère ne pas répondre',
                },
                {
                    name: '24',
                    label: 'Moins de 25 000 $',
                },
                {
                    name: '25',
                    label: '25 000 à 99 000 $',
                },
                {
                    name: '100',
                    label: '100 000 $ à 249 999 $',
                },
                {
                    name: '250',
                    label: '250 000 $ à 500 000 $',
                },
                {
                    name: '500',
                    label: '500 000 $ et plus',
                },
            ],
            postFormOnly: true,
        },
        legalHelpAfter: {
            label: 'Pensez-vous avoir besoin d’un accompagnement juridique au-delà de votre incorporation?',
            fieldType: 'yesNoDontKnow',
            postFormOnly: true,
        },
        otherDetails: {
            label: 'Souhaitez-vous nous fournir d’autres détails que vous jugez importants?',
            postFormOnly: true,
        },
    },
    avocatsPME: {
        label1: {
            label: 'Votre entreprise',
            fieldType: 'label',
            preFormOnly: true,
        },
        businessName: {
            label: 'Quel est le nom de votre entreprise?',
            preFormOnly: true,
        },
        job: {
            label: 'Quelle est votre fonction au sein de l’entreprise?',
            preFormOnly: true,
        },
    },
    business: {
        businessRepInd: {
            adminLabel: `Représente une entreprise?`,
            label: `Est-ce que vous représentez une entreprise existante ou à être créée`,
            fieldType: 'yesNo',
            autoCheckCondition: (val) => val === 'yes',
        },
        businessName: {
            label: `Quel est le nom de l'entreprise?`,
            dependsOn: {
                fieldName: 'businessRepInd',
                toBe: 'yes',
            },
        },
        activitySector: {
            adminLabel: `Secteur d'activité`,
            label: `Quel est le secteur d'activité de l'entreprise`,
            dependsOn: {
                fieldName: 'businessRepInd',
                toBe: 'yes',
            },
        },
        numEmployees: {
            adminLabel: `Nombre d'employés`,
            label: `Combien d’employé(s) emploie l'entreprise?`,
            dependsOn: {
                fieldName: 'businessRepInd',
                toBe: 'yes',
            },
            fieldType: 'select',
            options: [
                {
                    name: 'none',
                    label: 'Aucun',
                },
                {
                    name: '1',
                    label: '1 à 5',
                },
                {
                    name: '2',
                    label: '6 à 15',
                },
                {
                    name: '3',
                    label: '16 à 25',
                },
                {
                    name: '4',
                    label: '26 à 50',
                },
                {
                    name: '5',
                    label: '50 et plus',
                },
            ],
        },
        businessCaseType: {
            label: 'Votre dossier est en lien avec quelle situation',
            fieldType: 'select',
            options: [
                {
                    name: 'transaction',
                    label: 'Achat, vente, fusion, transfert ou réorganisation d’entreprise',
                },
                {
                    name: 'startUp',
                    label: 'Démarrage d’entreprise',
                },
                {
                    name: 'employeeLitigation',
                    label: 'Litige avec un employé',
                },
                {
                    name: 'shareholderLitigation',
                    label: 'Litige avec un actionnaire',
                },
                {
                    name: 'otherLitigation',
                    label: 'Autre litige',
                },
                {
                    name: 'bankruptcy',
                    label: 'Faillite et insolvabilité',
                },
                {
                    name: 'fiscal',
                    label: 'Fiscalité et taxes',
                },
                {
                    name: 'nonProfit',
                    label: 'Organisme à but non lucratif',
                },
                {
                    name: 'contract',
                    label: "Rédaction ou étude d'un contrat existant",
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
            autoCheck: true,
            dependsOn: {
                fieldName: 'businessRepInd',
                toBe: 'yes',
            },
        },
        buyerOrSeller: {
            label: 'Êtes-vous acheteur ou vendeur?',
            fieldType: 'select',
            options: [
                {
                    name: 'buyer',
                    label: 'Acheteur',
                },
                {
                    name: 'seller',
                    label: 'Vendeur',
                },
            ],
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'transaction',
            },
            autoCheck: true,
        },
        price: {
            label: 'Quel est le prix d’achat/vente/transfert estimé pour la transaction?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'transaction',
            },
        },
        purchaseFinancing: {
            label: 'Est-ce que l’achat implique la mise en place d’un financement?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'buyerOrSeller',
                toBe: 'buyer',
            },
        },
        purchaseFinancingAmount: {
            label: 'Quel est le montant du financement?',
            dependsOn: {
                fieldName: 'purchaseFinancing',
                toBe: 'yes',
            },
        },

        realestate: {
            label: 'Est-ce que la transaction implique un immeuble?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'transaction',
            },
        },
        accountantOnCase: {
            label: 'Est-ce qu’un comptable est au dossier?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'transaction',
            },
            fieldType: 'yesNo',
        },
        fiscalistOnCase: {
            label: 'Est-ce qu’un fiscaliste est au dossier?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'transaction',
            },
            fieldType: 'yesNo',
        },
        jobType: {
            label: 'Quelle est votre fonction au sein de l’entreprise?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'startUp',
            },
            autoCheck: true,
        },
        partnersNumber: {
            label: 'Combien de partenaires seront également actionnaires/associés?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'startUp',
            },
        },
        convention: {
            label: 'Pensez-vous avoir besoin d’une convention d’actionnaires ou autre contrat de démarrage?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'startUp',
            },
            fieldType: 'yesNoDontKnow',
            autoCheckCondition: (val) => val === 'yes',
        },
        turnover: {
            label: 'Quel est le chiffre d’affaires visé pour votre première année d’existence?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'startUp',
            },
        },
        needsFinancing: {
            label: 'Combien de financement pensez-vous avoir besoin aux fins de votre démarrage?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'startUp',
            },
            fieldType: 'select',
            options: [
                {
                    name: 'none',
                    label: 'Aucun',
                },
                {
                    name: 'less25k',
                    label: 'Moins de 25 000 $',
                },
                {
                    name: '25to99k',
                    label: '25 000 à 99 000 $',
                },
                {
                    name: '100to249k',
                    label: '100 000 $ à 249 999 $',
                },
                {
                    name: '250to500k',
                    label: '250 000 $ à 500 000 $',
                },
                {
                    name: '500kplus',
                    label: '500 000 $ et plus',
                },
                {
                    name: 'dontKnow',
                    label: 'Je ne sais pas / préfère ne pas répondre',
                },
            ],
        },
        unionized: {
            label: 'Est-ce que vos employés sont syndiqués?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'employeeLitigation',
            },
            fieldType: 'yesNo',
            autoCheckCondition: (val) => val === 'no',
        },
        signedUnionConvention: {
            label: 'Est-ce qu’une convention collective ou un renouvellement de convention collective doit-être (re)négocié?',
            dependsOn: {
                fieldName: 'unionized',
                toBe: 'yes',
            },
            fieldType: 'yesNo',
        },
        complaint: {
            label: 'Est-ce qu’une plainte, grief, mise en demeure ou procédure judiciaire a été transmise ou reçue?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'employeeLitigation',
            },
            fieldType: 'yesNo',
        },

        shareholderNumber: {
            label: 'Combien y a-t-il d’actionnaires/associés dans l’entreprise?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'shareholderLitigation',
            },
            autoCheck: true,
        },
        shareholderConvention: {
            label: 'Est-ce qu’il y a une convention d’actionnaires ou contrat de société',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'shareholderLitigation',
            },
            fieldType: 'yesNoDontKnow',
            autoCheck: true,
        },
        sellStocks: {
            label: 'Souhaitez-vous que vos parts soient rachetées?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'shareholderLitigation',
            },
            fieldType: 'yesNo',
            autoCheck: true,
        },
        sellStocksValue: {
            label: 'Quelle est la valeur estimée des parts à vendre?',
            dependsOnOne: [
                {
                    fieldName: 'sellStocks',
                    toBe: 'yes',
                },
            ],
            autoCheck: true,
        },
        buyStocks: {
            label: 'Souhaitez-vous racheter des parts?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'shareholderLitigation',
            },
            fieldType: 'yesNo',
            autoCheck: true,
        },
        buyStocksValue: {
            label: 'Quelle est la valeur estimée des parts à racheter?',
            dependsOnOne: [
                {
                    fieldName: 'buyStocks',
                    toBe: 'yes',
                },
            ],
            autoCheck: true,
        },

        businessValue: {
            label: 'Quelle est la valeur estimée de l’entreprise?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'shareholderLitigation',
            },
        },

        whoIsOtherParty: {
            label: 'Qui est l’autre partie par rapport à vous?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'otherLitigation',
            },
            fieldType: 'select',
            options: [
                {
                    name: 'lessor',
                    label: 'Bailleur',
                },
                {
                    name: 'supplier',
                    label: 'Fournisseur',
                },
                {
                    name: 'client',
                    label: 'Client',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
        },
        otherParty: {
            label: 'Autre rapport',
            dependsOn: {
                fieldName: 'whoIsOtherParty',
                toBe: 'other',
            },
        },
        moneyBeingClaimed: {
            label: "Si on vous réclame de l'argent, combien vous réclame-t-on?",
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'otherLitigation',
            },
        },
        moneyOwed: {
            label: "Si on vous doit de l'argent, combien vous doit-on?",
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'otherLitigation',
            },
        },
        judicialized: {
            label: 'Est-ce qu’une mise en demeure ou une procédure judiciaire a été transmise',
            dependsOnOne: [
                {
                    fieldName: 'businessCaseType',
                    toBe: 'shareholderLitigation',
                },
                {
                    fieldName: 'businessCaseType',
                    toBe: 'otherLitigation',
                },
            ],
        },
        creditorOrDebtor: {
            label: 'Est-ce que vous êtes',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'bankruptcy',
            },
            fieldType: 'select',
            options: [
                {
                    name: 'creditor',
                    label: 'Créancier d’un débiteur en voie d’être ou en situation de faillite',
                },
                {
                    name: 'debtor',
                    label: 'Débiteur en voie d’être ou en situation de faillite',
                },
            ],
        },
        creditorAmount: {
            label: 'Quel est le montant de votre créance?',
            dependsOn: {
                fieldName: 'creditorOrDebtor',
                toBe: 'creditor',
            },
        },

        noticeOfAssessment: {
            label: 'Avez-vous reçu un ou des avis de cotisation?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'fiscal',
            },
            fieldType: 'yesNo',
        },
        assessmentAmount: {
            label: 'À combien s’élève le montant total réclamé?',
            dependsOn: {
                fieldName: 'noticeOfAssessment',
                toBe: 'yes',
            },
        },
        contractType: {
            label: 'De quel type de contrat s’agit-il',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'contract',
            },
            fieldType: 'select',
            options: [
                {
                    name: 'tender',
                    label: 'Appel d’offres',
                },
                {
                    name: 'commercialLease',
                    label: 'Bail commercial',
                },
                {
                    name: 'serviceContract',
                    label: 'Contrat de service',
                },
                {
                    name: 'saleContract',
                    label: 'Contrat de vente',
                },
                {
                    name: 'workContract',
                    label: 'Contrat de travail',
                },
                {
                    name: 'shareholderConvention',
                    label: 'Convention d’actionnaires / contrat d’associés',
                },
                {
                    name: 'franchise',
                    label: 'Franchise',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
        },
        otherContractType: {
            label: 'Autre type de contrat',
            dependsOn: {
                fieldName: 'contractType',
                toBe: 'other',
            },
        },
        contractValue: {
            label: 'Quelle est la valeur estimée du contrat?',
            dependsOn: {
                fieldName: 'businessCaseType',
                toBe: 'contract',
            },
        },
        otherDetails: {
            label: 'Souhaitez-vous nous fournir d’autres détails importants?',
        },

        needsAccountant: {
            adminLabel: `Veut un comptable`,
            label: `Souhaiteriez-vous également être mis en relation avec un comptable d’affaires?`,
            fieldType: `yesNo`,
            showAtTheEnd: true,
        },
        needsEtienne: {
            admin: 'Veut Etienne',
            label: `Demander un diagnostic de votre situation financière comme propriétaire d'entreprise (gestion de patrimoine, optimisation fiscale et gestion des risques)`,
            fieldType: `yesNo`,
            showAtTheEnd: true,
        },
    },
    criminal: {
        hasCourtDate: {
            label: 'Avez-vous une date de Cour/Comparution devant le tribunal?',
            fieldType: 'yesNoDontKnow',
            autoCheckCondition: (val) => val === 'yes',
        },
        courtDate: {
            label: 'Date de cour',
            fieldType: 'date',
            dependsOn: {
                fieldName: 'hasCourtDate',
                toBe: 'yes',
            },
            autoCheck: true,
        },
        courtDateCity: {
            label: 'Dans quelle ville est-ce que votre dossier est devant la Cour?',
            dependsOn: {
                fieldName: 'hasCourtDate',
                toBe: 'yes',
            },
        },
        jobType: {
            label: 'Quelle est votre occupation/emploi?',
        },
        employementPeril: {
            label: 'Est-ce qu’être reconnu coupable de l’infraction pourrait mettre en péril votre emploi?',
            fieldType: 'yesNoDontKnow',
        },
        hasCriminalFile: {
            label: 'Est-ce que vous avez un dossier criminel?',
            fieldType: 'yesNoDontKnow',
            autoCheckCondition: (val) => val === 'yes',
        },
        infraction: {
            label: 'Quelle est la nature de l’infraction pour laquelle vous êtes accusé (vous pouvez indiquer les articles de lois pertinents si vous les connaissez)?',
        },
        multipleInfractions: {
            label: 'Est-ce que vous êtes accusé de plusieurs infractions?',
            fieldType: 'yesNoDontKnow',
        },
        caseDescription: {
            label: 'Souhaitez-vous nous fournir d’autres détails importants?',
        },
        hasLawyer: {
            label: 'Avez-vous déjà un avocat?',
            fieldType: 'yesNo',
            autoCheckCondition: (val) => val === 'no',
        },
        reasonLawyerChange: {
            label: 'Préciser la raison qui vous motive à changer d’avocat ainsi que son nom (pour éviter de vous mettre en relation avec ce dernier)',
            dependsOn: {
                fieldName: 'hasLawyer',
                toBe: 'yes',
            },
        },
    },
    'crim-transport': {
        impairedBy: {
            label: 'De quel type de facultées affaiblies êtes-vous accusé?',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'alcohol',
                    label: 'Alcool',
                },
                {
                    value: 'drugs',
                    label: 'Drogue',
                },
                {
                    value: 'both',
                    label: 'Alcool et Drogue',
                },
            ],
            preFormOnly: true,
        },
        hasCourtDate: {
            label: 'Avez-vous une date de cour?',
            fieldType: 'yesNoDontKnow',
            preFormOnly: true,
        },
        courtDate: {
            label: 'Date de cour',
            fieldType: 'date',
            dependsOn: {
                fieldName: 'hasCourtDate',
                toBe: 'yes',
            },
            preFormOnly: true,
        },
        courtDateCity: {
            label: 'Dans quelle ville est-ce que votre dossier est devant la Cour?',
            dependsOn: {
                fieldName: 'hasCourtDate',
                toBe: 'yes',
            },
            preFormOnly: true,
        },
        hasCriminalFile: {
            label: 'Est-ce votre première offense (si vous avez déjà un dossier criminel, répondez « non »)?',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'yes',
                    label: 'Oui',
                },
                {
                    value: 'no',
                    label: 'Non',
                },
                {
                    value: 'dontWantToAnswer',
                    label: 'Je préfère ne pas répondre',
                },
            ],
            preFormOnly: true,
        },
        multipleInfractions: {
            label: 'Est-ce que vous êtes accusé de plusieurs infractions?',
            fieldType: 'yesNoDontKnow',
            preFormOnly: true,
        },
        accident: {
            label: 'Est-ce qu’il y a eu un accident?',
            fieldType: 'yesNoDontKnow',
            preFormOnly: true,
        },
        accidentInjuries: {
            label: 'Est-ce que l’accident a causé des blessures?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'accident',
                toBe: 'yes',
            },
            preFormOnly: true,
        },
        accidentDeaths: {
            label: 'Est-ce que l’accident a causé un ou des décè(s)?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'accident',
                toBe: 'yes',
            },
            preFormOnly: true,
        },
        moreDetails: {
            label: 'Souhaitez-vous nous fournir d’autres détails que vous jugez importants?',
            preFormOnly: true,
        },
    },
    rights: {
        procedureStarted: {
            label: `Procédures instituées auprès d'un tribunal?`,
            fieldType: 'yesNoDontKnow',
        },
        procedureStartedDt: {
            adminLabel: `Date d'audience`,
            label: 'Avez-vous une date d’audience?',
            fieldType: 'date',
            dependsOn: {
                fieldName: 'procedureStarted',
                toBe: 'yes',
            },
        },
    },
    bankruptcy: {
        personType: {
            label: 'Vous êtes un:',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'person',
                    label: 'Particulier',
                },
                {
                    value: 'enterprise',
                    label: 'Entreprise',
                },
            ],
            autoCheckCondition: (val) => val === 'enterprise',
        },
        amount: {
            label: "Quel est le montant d'argent impliqué?",
            fieldType: 'number',
        },
        proposal: {
            label: 'Avez-vous déjà fait une proposition à vos créanciers?',
            fieldType: 'yesNoDontKnow',
        },
        syndicate: {
            label: 'Avez-vous consulté un syndic de faillite?',
            fieldType: 'yesNoDontKnow',
        },
        firstTime: {
            label: 'Est-ce votre première faillite?',
            fieldType: 'yesNo',
        },
        objective: {
            label: 'Objectifs de votre demande',
            fieldType: 'select',
            options: [
                {
                    name: 'consult',
                    label: 'Uniquement une consultation avec un avocat',
                },
                {
                    name: 'info',
                    label: 'Obtenir informations juridiques pour évaluer de confier mandat à un avocat',
                },
                {
                    name: 'quick',
                    label: 'Mandater rapidement un avocat pour mon dossier',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
            autoCheckCondition: (val) => val === 'info',
        },
        otherObjective: {
            label: 'Autre objectif',

            dependsOn: {
                fieldName: 'objective',
                toBe: 'other',
            },
        },
    },
    family: {
        details: {
            label: 'Détails de votre dossier',
            fieldType: 'label',
            preFormOnly: true,
        },

        amicable: {
            label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
            fieldType: 'yesNoDontKnow',
            preFormOnly: true,
        },
        judicialized: {
            label: 'Est-ce que votre dossier est actuellement devant la Cour (mise en demeure, procédure reçue ou transmise, audition devant le tribunal)?',
            fieldType: 'yesNoDontKnow',
        },
        judicializedCity: {
            label: 'Dans quelle ville est-ce que votre dossier est devant la Cour?',
            dependsOn: {
                fieldName: 'judicialized',
                toBe: 'yes',
            },
        },
        objective: {
            label: 'Objectifs de votre demande',
            fieldType: 'select',
            options: [
                {
                    name: 'consult',
                    label: 'Uniquement une consultation avec un avocat',
                },
                {
                    name: 'info',
                    label: 'Obtenir informations juridiques pour évaluer de confier mandat à un avocat',
                },
                {
                    name: 'quick',
                    label: 'Mandater rapidement un avocat pour mon dossier',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
            autoCheckCondition: (val) => val === 'info',
        },
        otherObjective: {
            label: 'Autre objectif',

            dependsOn: {
                fieldName: 'objective',
                toBe: 'other',
            },
        },
        caseType: {
            label: 'Votre dossier implique',
            fieldType: 'checkBoxes',
            adminLabel: 'Type de dossier',
            smallLabel: '(vous pouvez cumuler les options)',
            options: [
                {
                    name: 'divorce',
                    label: 'Séparation / divorce',
                },
                {
                    name: 'pension',
                    label: 'Pension alimentaire / garde d’enfant',
                },
                {
                    name: 'dpj',
                    label: 'DPJ',
                },
                {
                    name: 'other',
                    label: 'Autre (préciser)',
                },
            ],
        },
        otherType: {
            label: 'Autre type de dossier',

            dependsOn: {
                fieldName: 'caseType',
                toBe: 'other',
            },
        },
        spouseRevenueRange: {
            label: 'Quelle situation représente le mieux le revenu annuel de votre mari/épouse/conjoint(e)?',
            adminLabel: 'Revenu annuel mari/épouse/conjoint(e)',
            fieldType: 'select',
            options: revenueRangesOrderedKeys
                .filter((r) => !revenueRanges[r].deprecated)
                .map((r) => ({
                    name: revenueRanges[r].value,
                    label: revenueRanges[r].label,
                })),
            postFormOnly: true,
            autoCheckCondition: (val) => val > 3 && val < 8,
        },
        includesRealEstate: {
            label: 'Est-ce que votre patrimoine familial comprend un ou des biens immobiliers (cela comprend la résidence familiale)?',
            fieldType: 'yesNoDontKnow',
            postFormOnly: true,
            dependsOn: {
                fieldName: 'caseType',
                toBe: 'divorce',
            },
            autoCheckCondition: (val) => val === 'yes',
        },
        investments: {
            label: 'Est-ce que votre patrimoine familial comprend des placements (Reer, Céli, fond de pension etc)',
            fieldType: 'yesNoDontKnow',
            postFormOnly: true,
            dependsOn: {
                fieldName: 'caseType',
                toBe: 'divorce',
            },
            autoCheckCondition: (val) => val === 'yes',
        },
        business: {
            label: 'Est-ce que vous ou votre mari/épouse/conjoint(e) possédez une entreprise?',
            fieldType: 'yesNoDontKnow',
            postFormOnly: true,
            dependsOn: {
                fieldName: 'caseType',
                toBe: 'divorce',
            },
            autoCheckCondition: (val) => val === 'yes',
        },
        assets: {
            label: 'Quelle est la valeur totale estimée du patrimoine familial (immeuble, meuble, automobile, placement, fond de pension etc)',
            postFormOnly: true,
            dependsOn: {
                fieldName: 'caseType',
                toBe: 'divorce',
            },
        },
        kids: {
            label: 'Votre dossier implique la garde / pension alimentaire pour combien d’enfants?',
            fieldType: 'select',
            options: [
                {
                    name: '1',
                    label: '1',
                },
                {
                    name: '2',
                    label: '2',
                },
                {
                    name: '3',
                    label: '3',
                },
                {
                    name: '4',
                    label: '4 et +',
                },
            ],
            autoCheck: true,
            dependsOn: {
                fieldName: 'caseType',
                toBe: 'pension',
            },
        },
        custodyScenario: {
            label: 'Quel est le scénario souhaité pour la garde?',
            fieldType: 'select',
            options: [
                {
                    name: 'full_custody',
                    label: 'Avoir la garde temps plein',
                },
                {
                    name: 'major_custody_75_25',
                    label: 'Avoir la garde majoritaire (exemple 75/25)',
                },
                {
                    name: 'equal_custody_50_50',
                    label: 'Garde 50/50',
                },
                {
                    name: 'minor_custody_25_75',
                    label: 'Confier la garde majoritaire (exemple 25/75)',
                },
                {
                    name: 'non_custodial',
                    label: 'Confier la garde temps plein',
                },
            ],
            autoCheck: true,
            dependsOn: {
                fieldName: 'caseType',
                toBe: 'pension',
            },
        },
        pensionScenario: {
            label: 'Quel est le scénario souhaité pour la pension?',
            fieldType: 'select',
            options: [
                {
                    name: 'stop_alimony',
                    label: 'Arrêter le paiement d’une pension alimentaire',
                },
                {
                    name: 'no_alimony',
                    label: 'Ne pas recevoir / ni verser de montant',
                },
                {
                    name: 'receive_minimal_amount',
                    label: 'Recevoir un montant mensuel minimal de',
                },
                {
                    name: 'pay_maximal_amount',
                    label: 'Donner un montant mensuel maximal de',
                },
            ],
            dependsOn: {
                fieldName: 'caseType',
                toBe: 'pension',
            },
        },
        pensionAmount: {
            label: 'Montant',
            dependsOn: {
                fieldName: 'pensionScenario',
                toBe: ['receive_minimal_amount', 'pay_maximal_amount'],
            },
        },
        courtDate: {
            label: 'Avez-vous une date de Cour pour votre dossier DPJ? Si oui, préciser',
            fieldType: 'date',
            dependsOn: {
                fieldName: 'caseType',
                toBe: 'dpj',
            },
            autoCheckCondition: (val) => val === 'yes',
        },
        caseDescription: {
            label: 'Voulez-vous ajouter des détails?',
            fieldType: 'text',
            preFormOnly: true,
            smallLabel: 'facultatif',
        },
        othersInvolved: {
            label: 'Autre(s) partie(s) impliquées',
            smallLabel: 'Par exemple le nom de votre conjoint(e)',
            preFormOnly: true,
            required: true,
        },
        home: {
            label: 'Êtes-vous propriétaire ou locataire de votre résidence familiale?',
            fieldType: 'select',
            options: [
                {
                    name: 'owner',
                    label: 'Propriétaire',
                },
                {
                    name: 'rent',
                    label: 'Locataire',
                },
                {
                    name: 'neither',
                    label: 'Je ne suis ni propriétaire ni locataire',
                },
                {
                    name: 'dontKnow',
                    label: 'Ne sais pas',
                },
            ],
        },
        needsRealEstateBroker: {
            adminLabel: `Veut un courtier immobillier`,
            label: `Si le patrimoine familial implique un ou des immeubles, souhaiteriez-vous également être mis en relation avec un courtier immobilier?`,
            fieldType: `yesNo`,
            showAtTheEnd: true,
            dependsOn: {
                fieldName: 'caseType',
                toBe: 'divorce',
            },
        },
        needsNotary: {
            adminLabel: 'Veut un notaire',
            label: `En cas de séparation ou de divorce, souhaiteriez-vous être mis en relation avec un notaire pour mettre à jour ou modifier votre testament?`,
            fieldType: 'yesNo',
            showAtTheEnd: true,
            dependsOn: {
                fieldName: 'caseType',
                toBe: 'divorce',
            },
        },
    },
    'family-family': {
        label1: {
            label: 'Séparation ou Divorce',
            fieldType: 'label',
            preFormOnly: true,
        },
        kids: {
            label: 'Est-ce que votre dossier implique la garde d’un ou de plusieurs enfants?',
            fieldType: 'yesNo',
            preFormOnly: true,
        },
        judicialized: {
            label: 'Est-ce que votre dossier est judiciarisé (une mise en demeure ou autre procédure a déjà été reçue ou transmise)?',
            adminLabel: 'Dossier judiciarisé',
            fieldType: 'yesNoDontKnow',
            preFormOnly: true,
        },
        amicable: {
            label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
            fieldType: 'yesNoDontKnow',
            preFormOnly: true,
        },
        otherDetails: {
            label: 'Souhaitez-vous nous fournir d’autres détails que vous jugez importants?',
            preFormOnly: true,
        },
        includesRealEstate: {
            label: 'Est-ce que votre patrimoine familial comprend un ou des biens immobiliers (cela comprend la résidence familiale)?',
            fieldType: 'yesNoDontKnow',
            postFormOnly: true,
            autoCheckCondition: (val) => val === 'yes',
        },
        business: {
            label: 'Est-ce que vous ou votre mari/épouse/conjoint(e) possédez une entreprise?',
            postFormOnly: true,
            autoCheckCondition: (val) => val === 'yes',
        },
        revenueRange: {
            label: 'Quelle situation représente le mieux votre revenu annuel?',
            adminLabel: 'Revenu annuel justiciable',
            fieldType: 'select',
            options: revenueRangesOrderedKeys
                .filter((r) => !revenueRanges[r].deprecated)
                .map((r) => ({
                    name: revenueRanges[r].value,
                    label: revenueRanges[r].label,
                })),
            postFormOnly: true,
            autoCheckCondition: (val) => val > 3 && val < 8,
        },
        spouseRevenueRange: {
            label: 'Quelle situation représente le mieux le revenu annuel de votre mari/épouse/conjoint(e)?',
            adminLabel: 'Revenu annuel mari/épouse/conjoint(e)',
            fieldType: 'select',
            options: revenueRangesOrderedKeys
                .filter((r) => !revenueRanges[r].deprecated)
                .map((r) => ({
                    name: revenueRanges[r].value,
                    label: revenueRanges[r].label,
                })),
            postFormOnly: true,
            autoCheckCondition: (val) => val > 3 && val < 8,
        },
    },

    fiscal: {
        personType: {
            label: 'Vous êtes un:',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'person',
                    label: 'Particulier',
                },
                {
                    value: 'enterprise',
                    label: 'Entreprise',
                },
            ],
            autoCheckCondition: (val) => val === 'enterprise',
        },
        money: {
            label: "Montant d'argent impliqué dans le dossier, si applicable",
            fieldType: 'number',
        },
        objective: {
            label: 'Objectifs de la demande',
            fieldType: 'select',
            options: [
                {
                    name: 'consultation',
                    label: 'Uniquement une consultation avec un avocat',
                },
                {
                    name: 'infoMandate',
                    label: 'Obtenir Informations juridiques pour confier mandat à un avocat',
                },
                {
                    name: 'mandate',
                    label: 'Mandater rapidement un avocat pour mon dossier',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
            autoCheckCondition: (val) =>
                val === 'infoMandate' || val === 'mandate',
        },
        activitySector: {
            adminLabel: `Secteur d'activité`,
            label: `Quel est le secteur d'activité de l'entreprise`,
            dependsOn: {
                fieldName: 'personType',
                toBe: 'enterprise',
            },
        },
        numEmployees: {
            adminLabel: `Nombre d'employés`,
            label: `Combien d’employé(s) emploie l'entreprise?`,
            fieldType: 'select',
            options: [
                {
                    name: 'none',
                    label: 'Aucun',
                },
                {
                    name: '1',
                    label: '1 à 5',
                },
                {
                    name: '2',
                    label: '6 à 15',
                },
                {
                    name: '3',
                    label: '16 à 25',
                },
                {
                    name: '4',
                    label: '26 à 50',
                },
                {
                    name: '5',
                    label: '50 et plus',
                },
            ],
            dependsOn: {
                fieldName: 'personType',
                toBe: 'enterprise',
            },
        },
        job: {
            label: 'Quelle est votre fonction au sein de l’entreprise?',
        },
    },
    immigration: {
        alreadyInCanada: {
            label: 'Êtes-vous déjà au Canada?',
            fieldType: 'yesNo',
            autoCheckCondition: (val) => val === 'yes',
        },
        country: {
            label: 'Quel est votre pays de citoyenneté',
        },
        diploma: {
            label: 'Quel est votre niveau de scolarité? (dernier diplôme)',
        },
        studentOrWorker: {
            label: 'Êtes-vous étudiant ou travailleur?',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'student',
                    label: 'Étudiant',
                },
                {
                    value: 'worker',
                    label: 'Travailleur',
                },
            ],
            autoCheckCondition: (val) => val === 'worker',
        },
        currentDiploma: {
            label: 'Quel est votre programme d’étude?',
            dependsOn: {
                fieldName: 'studentOrWorker',
                toBe: 'student',
            },
        },
        acceptedStudent: {
            label: 'Avez-vous déjà été accepté dans un établissement scolaire au Canada?',
            fieldType: 'yesNo',
            dependsOn: {
                fieldName: 'studentOrWorker',
                toBe: 'student',
            },
        },
        work: {
            label: 'Quel travail (métier ou profession) exercez-vous et depuis quand?',
            dependsOn: {
                fieldName: 'studentOrWorker',
                toBe: 'worker',
            },
        },
        acceptedWorker: {
            label: 'Avez-vous déjà accepté une offre d’emploi au Canada?',
            fieldType: 'yesNo',
            dependsOn: {
                fieldName: 'studentOrWorker',
                toBe: 'worker',
            },
        },
        netWorth: {
            label: 'Quelle est votre valeur nette personnelle en dollars Canadiens (certains programmes l’exigent)?',
            fieldType: 'select',
            options: [
                {
                    name: '10k',
                    label: 'Moins de 10 000 $',
                },
                {
                    name: '10-25k',
                    label: 'Entre 10 000 $ et 25 000 $',
                },
                {
                    name: '25-100k',
                    label: 'Entre 25 000 $ et 100 000 $',
                },
                {
                    name: '100-500k',
                    label: 'Entre 100 000 $ et 500 000 $',
                },
                {
                    name: '500-1000k',
                    label: 'Entre 500 000 $ et 1 000 000 $',
                },
                {
                    name: '1000-2000k',
                    label: 'Entre 1 000 000 $ et 2 000 000 $',
                },
                {
                    name: '2000k',
                    label: 'Plus de 2 000 000 $',
                },
            ],
        },
        processStep: {
            label: 'À quelle étape êtes-vous rendu dans votre processus d’immigration?',
            fieldType: 'select',
            options: [
                {
                    name: 'start',
                    label: 'Je veux initier les démarches d’immigration',
                },
                {
                    name: 'submitted',
                    label: 'J’ai déposé ma demande pour obtenir (ou j’ai déjà) un permis de travail/étude, visa, résidence permanente, etc., et je veux maintenant l’aide d’un avocat',
                },
                {
                    name: 'denied',
                    label: 'Ma demande a été refusée, et je souhaite contester cette décision',
                },
                {
                    name: 'hearing',
                    label: 'J’ai une audience déjà fixée',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
        },
        otherProcessStep: {
            label: 'Autre étape',
            dependsOn: {
                fieldName: 'processStep',
                toBe: 'other',
            },
        },
        history: {
            label: `Avez-vous des antécédents d'immigration au Canada?`,
            fieldType: 'select',
            options: [
                {
                    name: 'no',
                    label: 'Non',
                },
                {
                    name: 'visa',
                    label: 'Refus de visa',
                },
                {
                    name: 'workStudyPermit',
                    label: 'Refus de permis de travail/étude',
                },
                {
                    name: 'permResi',
                    label: 'Refus de résidence permanente',
                },
                {
                    name: 'entry',
                    label: `Refus d'entrée`,
                },
                {
                    name: 'overstay',
                    label: 'Situations de dépassement de séjour',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
        },
        otherHistory: {
            label: `Autre antécédents d'immigration`,
            dependsOn: {
                fieldName: 'history',
                toBe: 'other',
            },
        },
    },
    realestate: {
        realestateType: {
            label: 'Votre dossier implique',
            fieldType: 'select',
            options: [
                {
                    name: 'transaction',
                    label: 'Une transaction immobilière',
                },
                {
                    name: 'vice',
                    label: 'Un vice caché',
                },
                {
                    name: 'condo',
                    label: 'Une copropriété',
                },
                {
                    name: 'construction',
                    label: 'Construction',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
            autoCheckCondition: (val) => val !== 'other',
        },
        amicable: {
            label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'realestateType',
                toBe: 'other',
            },
        },
        judicialized: {
            label: 'Est-ce que votre dossier est déjà judiciarisé (procédure déposée ou reçue)?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'realestateType',
                toBe: 'other',
            },
        },
        amount: {
            label: 'Si vous pouviez accorder une valeur monétaire à votre litige, vous l’évalueriez à combien? Il peut s’agir du montant de la réclamation, s’il y en a un',
            adminLabel: 'Montant',
            dependsOn: {
                fieldName: 'realestateType',
                toBe: 'other',
            },
        },
        'realestate-transaction': {
            fieldType: 'group',
            fields: realestateTransaction,
            dependsOn: {
                fieldName: 'realestateType',
                toBe: 'transaction',
            },
        },
        'realestate-vice': {
            fieldType: 'group',
            fields: realestateVice,
            dependsOn: {
                fieldName: 'realestateType',
                toBe: 'vice',
            },
        },
        'realestate-condo': {
            fieldType: 'group',
            fields: realestateCondo,
            dependsOn: {
                fieldName: 'realestateType',
                toBe: 'condo',
            },
        },
        'realestate-construction': {
            fieldType: 'group',
            fields: realestateConstruction,
            dependsOn: {
                fieldName: 'realestateType',
                toBe: 'construction',
            },
        },
    },

    responsability: {
        judicialized: {
            label: 'Est-ce que votre dossier est déjà judiciarisé (procédure déposée ou reçue)?',
            fieldType: 'yesNoDontKnow',
        },
        injuries: {
            label: 'Est-ce que votre dossier implique une ou des blessures corporelles?',
            adminLabel: `Blessures corporelles?`,
            fieldType: 'yesNoDontKnow',
        },
        injuriesDesc: {
            adminLabel: `Descriptions des blessures`,
            label: 'Pouvez-vous décrire la ou les blessures?',
            dependsOn: {
                fieldName: 'injuries',
                toBe: 'yes',
            },
        },
        employmentPeril: {
            label: 'Est-ce que cette blessure vous empêche ou vous empêchera de poursuivre votre emploi?',
            adminLabel: 'Emploi en péril?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'injuries',
                toBe: 'yes',
            },
            autoCheckCondition: (val) => val === 'yes',
        },
        job: {
            label: 'Quel est ou était votre emploi?',
            dependsOn: {
                fieldName: 'injuries',
                toBe: 'yes',
            },
            adminLabel: 'Emploi',
        },
        diag: {
            label: `Avez-vous reçu un diagnostic d'un médecin suite à l’accident?`,
            fieldType: 'yesNo',
            dependsOn: {
                fieldName: 'injuries',
                toBe: 'yes',
            },
            autoCheckCondition: (val) => val === 'yes',
        },
        diagDesc: {
            label: 'Quel est le diagnostic?',
            dependsOn: {
                fieldName: 'diag',
                toBe: 'yes',
            },
        },
        injuryLinkedToJob: {
            label: 'La cause de votre accident est-elle reliée à votre emploi?',
            fieldType: 'yesNo',
            dependsOn: {
                fieldName: 'injuries',
                toBe: 'yes',
            },
        },
        objective: {
            label: 'Objectifs de votre demande',
            fieldType: 'select',
            options: [
                {
                    name: 'consult',
                    label: 'Uniquement une consultation avec un avocat',
                },
                {
                    name: 'info',
                    label: 'Obtenir informations juridiques pour évaluer de confier mandat à un avocat',
                },
                {
                    name: 'quick',
                    label: 'Mandater rapidement un avocat pour mon dossier',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
            autoCheckCondition: (val) => val === 'info' || val === 'quick',
        },
        otherObjective: {
            label: 'Autre objectif',

            dependsOn: {
                fieldName: 'objective',
                toBe: 'other',
            },
        },
    },
    succession: {
        // amicable: {
        //     label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
        //     fieldType: 'yesNoDontKnow',
        // },
        executor: {
            label: 'Est-ce que vous êtes l’exécuteur/liquidateur testamentaire?',
            fieldType: 'yesNoDontKnow',
            autoCheckCondition: (val) => val === 'yes',
        },
        heir: {
            label: 'Est-ce que vous êtes un héritier sur le testament?',
            fieldType: 'yesNoDontKnow',
            autoCheckCondition: (val) => val === 'yes',
        },
        heirsAmount: {
            label: 'Combien d’héritier y-a-il dans la succession?',
            fieldType: 'select',
            options: [
                {
                    name: '1',
                    label: '1',
                },
                {
                    name: '2',
                    label: '2',
                },
                {
                    name: '3',
                    label: '3',
                },
                {
                    name: '4',
                    label: '4',
                },
                {
                    name: '5+',
                    label: '5 et plus',
                },
            ],
            autoCheck: true,
        },
        business: {
            label: 'Est-ce que la succession comprend une entreprise?',
            fieldType: 'yesNoDontKnow',
            autoCheckCondition: (val) => val === 'yes',
        },
        realestate: {
            label: 'Est-ce que la succession détient un ou des immeubles?',
            fieldType: 'yesNoDontKnow',
            autoCheckCondition: (val) => val === 'yes',
        },
        amount: {
            label: 'Quelle est la valeur estimée de la succession?',
            adminLabel: 'Montant',
        },
        userAmount: {
            label: 'Quelle est la valeur estimée de votre part de la succession?',
        },
        formalNotice: {
            label: 'Avez-vous reçu ou transmis une mise en demeure ou une procédure judiciaire?',
            fieldType: 'yesNoDontKnow',
        },
        objective: {
            label: 'Objectifs de la demande',
            fieldType: 'select',
            options: [
                {
                    name: 'consultation',
                    label: 'Uniquement une consultation avec un avocat',
                },
                {
                    name: 'infoMandate',
                    label: 'Obtenir Informations juridiques pour confier mandat à un avocat',
                },
                {
                    name: 'mandate',
                    label: 'Mandater rapidement un avocat pour mon dossier',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
            autoCheckCondition: (val) =>
                val === 'infoMandate' || val === 'mandate',
        },
        otherObjective: {
            label: 'Si vous avez choisi « Autre », veuillez préciser',
            dependsOn: {
                fieldName: 'objective',
                toBe: 'other',
            },
        },
        otherDetails: {
            label: 'Souhaitez-vous nous fournir d’autres détails importants?',
        },

        needsRealEstateBroker: {
            adminLabel: `Veut un courtier immobillier`,
            label: `Si la succession comprend un ou des immeubles, souhaiteriez-vous également être mis en relation avec un courtier immobilier?`,
            fieldType: `yesNo`,
            showAtTheEnd: true,
        },
        needsAccountant: {
            adminLabel: `Veut un comptable`,
            label: `Aux fins du règlement de la succession ou de sa reddition de compte, souhaiteriez-vous également être mis en relation avec un comptable?`,
            fieldType: `yesNo`,
            showAtTheEnd: true,
        },
    },
    'work-termination': {
        label1: {
            label: 'Congédiement déguisé ou injustifié',
            fieldType: `label`,
            preFormOnly: true,
        },
        employeeType: {
            label: 'Êtes-vous employé ou employeur?',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'employee',
                    label: 'Employé',
                },
                {
                    value: 'employer',
                    label: 'Employeur',
                },
            ],
            preFormOnly: true,
        },
        howLong: {
            label: 'Depuis combien de temps êtes-vous à l’emploi de votre employeur?',
            fieldType: 'select',
            options: [
                {
                    name: '0',
                    label: 'Moins de 3 mois',
                },
                {
                    name: '1',
                    label: '3 mois à 1 an',
                },
                {
                    name: '2',
                    label: '1 à 2 ans',
                },
                {
                    name: '3',
                    label: '2 à 5 ans',
                },
                {
                    name: '4',
                    label: '5 à 10 ans',
                },
                {
                    name: '5',
                    label: '10 ans et plus',
                },
            ],
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employee',
            },
            preFormOnly: true,
        },
        jobType: {
            label: `Quelle est votre fonction ou poste chez votre employeur?`,
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employee',
            },
            preFormOnly: true,
        },
        management: {
            label: 'S’agit-il d’un poste de direction/cadre?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employee',
            },
            preFormOnly: true,
        },
        union: {
            label: 'Êtes-vous syndiqué?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employee',
            },
            preFormOnly: true,
        },
        employerName: {
            label: 'Indiquer le nom de  l’employeur',
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employee',
            },
            preFormOnly: true,
        },
        howLongEmployer: {
            label: 'Depuis combien de temps l’employé est-il à votre emploi?',
            fieldType: 'select',
            options: [
                {
                    name: '0',
                    label: 'Moins de 3 mois',
                },
                {
                    name: '1',
                    label: '3 mois à 1 an',
                },
                {
                    name: '2',
                    label: '1 à 2 ans',
                },
                {
                    name: '3',
                    label: '2 à 5 ans',
                },
                {
                    name: '4',
                    label: '5 à 10 ans',
                },
                {
                    name: '5',
                    label: '10 ans et plus',
                },
            ],
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employer',
            },
            preFormOnly: true,
        },
        jobTypeEmployer: {
            label: `Quelle est sa fonction au sein de l’entreprise?`,
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employer',
            },
            preFormOnly: true,
        },
        managementEmployer: {
            label: 'S’agit-il d’un poste de direction/cadre?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employer',
            },
            preFormOnly: true,
        },
        unionEmployer: {
            label: 'Est-il syndiqué?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employer',
            },
            preFormOnly: true,
        },
        numEmployees: {
            adminLabel: `Nombre d'employés`,
            label: `Combien d’employé(s) emploie l'entreprise?`,
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employer',
            },
            fieldType: 'select',
            options: [
                {
                    name: 'none',
                    label: 'Aucun',
                },
                {
                    name: '1',
                    label: '1 à 5',
                },
                {
                    name: '2',
                    label: '6 à 15',
                },
                {
                    name: '3',
                    label: '16 à 25',
                },
                {
                    name: '4',
                    label: '26 à 50',
                },
                {
                    name: '5',
                    label: '50 et plus',
                },
            ],
            preFormOnly: true,
        },
        activitySector: {
            adminLabel: `Secteur d'activité`,
            label: `Quel est le secteur d'activité de l'entreprise`,
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employer',
            },
            preFormOnly: true,
        },
        endOffer: {
            label: 'Avez-vous reçu ou formulé une offre d’indemnisation pour la fin d’emploi?',
            fieldType: 'yesNoDontKnow',
            postFormOnly: true,
        },
        endOfferAmount: {
            label: `Quel est le montant de l'offre?`,
            dependsOn: {
                fieldName: 'endOffer',
                toBe: 'yes',
            },
            postFormOnly: true,
        },
        procedure: {
            label: 'Avez-vous reçu ou transmis une procédure dans ce dossier? (mise en demeure, grief, plainte CNESST, etc)',
            fieldType: 'yesNoDontKnow',
            postFormOnly: true,
        },
        amicable: {
            label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
            fieldType: 'yesNoDontKnow',
            postFormOnly: true,
        },
        otherDetails: {
            label: 'Souhaitez-vous nous fournir d’autres détails que vous jugez importants?',
            postFormOnly: true,
        },
    },
    work: {
        employeeType: {
            label: 'Êtes-vous employé ou employeur?',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'employee',
                    label: 'Employé',
                },
                {
                    value: 'employer',
                    label: 'Employeur',
                },
                {
                    value: 'union',
                    label: 'Organisation syndicale',
                },
            ],
            autoCheckCondition: (val) => val === 'employer',
        },
        activitySector: {
            label: 'Quel est le secteur d’activité de votre entreprise?',
            adminLabel: `Secteur d'activité`,
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employer',
            },
        },
        numEmployees: {
            adminLabel: `Nombre d'employés`,
            label: `Combien d’employé(s) emploie l'entreprise?`,
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employer',
            },
            fieldType: 'select',
            options: [
                {
                    name: 'none',
                    label: 'Aucun',
                },
                {
                    name: '1',
                    label: '1 à 5',
                },
                {
                    name: '2',
                    label: '6 à 15',
                },
                {
                    name: '3',
                    label: '16 à 25',
                },
                {
                    name: '4',
                    label: '26 à 50',
                },
                {
                    name: '5',
                    label: '50 et plus',
                },
            ],
            preFormOnly: true,
        },
        collectiveAgreement: {
            label: `Est-ce qu'il y a une convention collective?`,

            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employer',
            },
            autoCheckCondition: (val) => val === 'no',
        },
        jobType: {
            label: 'Quelle est votre fonction au sein de l’entreprise?',
        },
        amicable: {
            label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
            fieldType: 'yesNoDontKnow',
        },
        howLong: {
            label: 'Depuis combien de temps êtes-vous à l’emploi de votre employeur?',
            fieldType: 'select',
            options: [
                {
                    name: '0',
                    label: 'Moins de 3 mois',
                },
                {
                    name: '1',
                    label: '3 mois à 1 an',
                },
                {
                    name: '2',
                    label: '1 à 2 ans',
                },
                {
                    name: '3',
                    label: '2 à 5 ans',
                },
                {
                    name: '4',
                    label: '5 à 10 ans',
                },
                {
                    name: '5',
                    label: '10 ans et plus',
                },
            ],
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employee',
            },
            autoCheckCondition: (val) => val !== '0' && val !== '1',
        },
        management: {
            label: 'S’agit-il d’un poste de direction/cadre?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employee',
            },
            autoCheckCondition: (val) => val === 'yes',
        },
        union: {
            label: 'Êtes-vous syndiqué?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employee',
            },
            autoCheckCondition: (val) => val === 'no',
        },
        employerName: {
            label: 'Indiquer le nom de  l’employeur',
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employee',
            },
            preFormOnly: true,
        },
        situation: {
            label: 'Qu’est-ce qui représente le mieux votre situation (si plus d’un choix correspond, indiquez la raison principale de votre démarche)',
            fieldType: 'select',
            options: [
                {
                    name: 'termination',
                    label: 'Congédiement',
                },
                {
                    name: 'harassment',
                    label: 'Harcèlement / discrimination',
                },
                {
                    name: 'accident',
                    label: 'Accident de travail',
                },
                {
                    name: 'unionIssue',
                    label: 'Représentation syndicale problématique',
                },
                {
                    name: 'collectiveInsurance',
                    label: 'Enjeux avec assurance collective',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employee',
            },
        },
        otherSituation: {
            label: 'Si vous avez choisi « Autre », veuillez préciser',
            dependsOn: {
                fieldName: 'situation',
                toBe: 'other',
            },
        },
        monetaryCompensation: {
            label: 'Avez-vous reçu une offre de compensation monétaire (délais de congé) lors de votre congédiement',
            fieldType: 'yesNo',
            dependsOn: {
                fieldName: 'situation',
                toBe: 'termination',
            },
        },
        monetaryCompensationAmount: {
            label: 'Si Oui, préciser le montant de l’offre reçue',
            dependsOn: {
                fieldName: 'monetaryCompensation',
                toBe: 'yes',
            },
        },
        cnesstComplaint: {
            label: 'Avez-vous déposé une plainte à la CNESST?',
            fieldType: 'yesNo',
            dependsOnOne: [
                {
                    fieldName: 'situation',
                    toBe: 'termination',
                },
                {
                    fieldName: 'situation',
                    toBe: 'harassment',
                },
            ],
        },
        formalNotice: {
            label: 'Avez-vous reçu ou transmis une mise en demeure ou une procédure judiciaire?',
            fieldType: 'yesNoDontKnow',
            dependsOnOne: [
                {
                    fieldName: 'situation',
                    toBe: 'termination',
                },
                {
                    fieldName: 'situation',
                    toBe: 'harassment',
                },
                {
                    fieldName: 'situation',
                    toBe: 'accident',
                },
            ],
        },
        stillEmployed: {
            label: 'Êtes-vous toujours à l’emploi de votre employeur?',
            fieldType: 'yesNo',
            dependsOn: {
                fieldName: 'situation',
                toBe: 'harassment',
            },
        },
        harassmentType: {
            label: 'Est-ce que l’harcèlement ou la discrimination reprochée découle de',
            fieldType: 'checkBoxes',
            options: [
                {
                    name: 'age',
                    label: 'Âge',
                },
                {
                    name: 'maternity',
                    label: 'Enceinte / Maternité / Paternité',
                },
                {
                    name: 'genre',
                    label: 'Genre',
                },
                {
                    name: 'handicap',
                    label: 'Handicap / Maladie',
                },
                {
                    name: 'sexual',
                    label: 'De nature sexuelle',
                },
                {
                    name: 'sexualOrientation',
                    label: 'Orientation sexuelle',
                },
                {
                    name: 'racial',
                    label: 'Racial / Culturel',
                },
                {
                    name: 'religion',
                    label: 'Religion',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
            dependsOn: {
                fieldName: 'situation',
                toBe: 'harassment',
            },
        },
        otherHarassmentType: {
            label: 'Si vous avez choisi « Autre », veuillez préciser',
            dependsOn: {
                fieldName: 'harassmentType',
                toBe: 'other',
            },
        },

        injury: {
            label: 'Avez-vous subi une blessure ou un accident de travail?',
            fieldType: 'yesNo',

            dependsOn: {
                fieldName: 'situation',
                toBe: 'accident',
            },
        },
        injuryDescription: {
            label: 'Si oui, veuillez décrire la nature de la blessure ou de l’accident',
            dependsOn: {
                fieldName: 'injury',
                toBe: 'yes',
            },
        },
        indemnity: {
            label: 'Est-ce que vous recevez une indemnité de votre employeur ou de la CNESST',
            fieldType: 'yesNo',
            dependsOn: {
                fieldName: 'situation',
                toBe: 'accident',
            },
        },
        indemnityAmount: {
            label: 'Si oui, veuillez préciser le montant de l’indemnité',
            dependsOn: {
                fieldName: 'indemnity',
                toBe: 'yes',
            },
        },
        hasCourtDate: {
            label: 'Est-ce que vous avez une date de Cour pour votre dossier?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'situation',
                toBe: 'accident',
            },
        },
        employerContests: {
            label: 'Est-ce que votre employeur conteste votre blessure et invalidité',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'situation',
                toBe: 'accident',
            },
        },
        claimType: {
            label: 'Quelle type de réclamation est faite à votre assurance collective',
            fieldType: 'select',
            options: [
                {
                    name: 'disability',
                    label: 'Invalidité',
                },
                {
                    name: 'life',
                    label: 'Vie',
                },
                {
                    name: 'health',
                    label: 'Santé',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
            dependsOn: {
                fieldName: 'situation',
                toBe: 'collectiveInsurance',
            },
        },
        otherClaimType: {
            label: 'Si vous avez choisi « Autre », veuillez préciser',
            dependsOn: {
                fieldName: 'claimType',
                toBe: 'other',
            },
        },
        insurerRefuses: {
            label: 'Est-ce que votre assureur refuse de vous payer?',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'yes',
                    label: 'Oui',
                },
                {
                    value: 'no',
                    label: 'Non',
                },
                {
                    value: 'partial',
                    label: 'Partiellement',
                },
            ],
            dependsOn: {
                fieldName: 'situation',
                toBe: 'collectiveInsurance',
            },
        },
        insurerRefusesDetails: {
            label: 'Si Oui ou partiellement, quels sont les motifs de l’assureur',

            dependsOnOne: [
                {
                    fieldName: 'insurerRefuses',
                    toBe: 'yes',
                },
                {
                    fieldName: 'insurerRefuses',
                    toBe: 'partial',
                },
            ],
        },
        insurerName: {
            label: 'Nom de l’assureur',
            dependsOn: {
                fieldName: 'situation',
                toBe: 'collectiveInsurance',
            },
        },
        claimAmount: {
            label: 'Quelle est la valeur approximative de votre réclamation',
            fieldType: 'number',
            dependsOn: {
                fieldName: 'situation',
                toBe: 'collectiveInsurance',
            },
        },
        howManyPastClaims: {
            label: 'Combien de réclamations avez-vous faites dans le passé',
            fieldType: 'select',

            options: [
                {
                    name: 'none',
                    label: 'Aucune',
                },
                {
                    name: 'one-two',
                    label: '1 à 2',
                },
                {
                    name: 'three-five',
                    label: '3 à 5',
                },
                {
                    name: '6-more',
                    label: '6 et plus',
                },
            ],
            dependsOn: {
                fieldName: 'situation',
                toBe: 'collectiveInsurance',
            },
        },
        insurerEverRefused: {
            label: 'Est-ce qu’un assureur a déjà refusé de vous couvrir',
            fieldType: 'yesNo',
            dependsOn: {
                fieldName: 'situation',
                toBe: 'collectiveInsurance',
            },
        },
        insurerEverRefusedDetails: {
            label: 'Si Oui, pour quels motifs',
            dependsOn: {
                fieldName: 'insurerEverRefused',
                toBe: 'yes',
            },
        },
        refusalEmployerProceedings: {
            label: 'Est-ce que des démarches ont été faites avec votre employeur relativement au refus de couverture?',
            fieldType: 'yesNo',
            dependsOn: {
                fieldName: 'situation',
                toBe: 'collectiveInsurance',
            },
        },
        objective: {
            label: 'Objectifs de la demande',
            fieldType: 'select',
            options: [
                {
                    name: 'consultation',
                    label: 'Uniquement une consultation avec un avocat',
                },
                {
                    name: 'infoMandate',
                    label: 'Obtenir Informations juridiques pour confier mandat à un avocat',
                },
                {
                    name: 'mandate',
                    label: 'Mandater rapidement un avocat pour mon dossier',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
            autoCheckCondition: (val) =>
                val === 'infoMandate' || val === 'mandate',
        },
        otherObjective: {
            label: 'Si vous avez choisi « Autre », veuillez préciser',
            dependsOn: {
                fieldName: 'objective',
                toBe: 'other',
            },
        },
        otherDetails: {
            label: 'Souhaitez-vous nous fournir d’autres détails importants?',
        },
    },
    civil: {
        amicable: {
            label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
            fieldType: 'yesNoDontKnow',
        },
        judicialized: {
            label: 'Est-ce que votre dossier est déjà judiciarisé (procédure déposée ou reçue)?',
            fieldType: 'yesNoDontKnow',
        },
        amount: {
            label: 'Si vous pouviez accorder une valeur monétaire à votre litige, vous l’évalueriez à combien? Il peut s’agir du montant de la réclamation, s’il y en a un',
            adminLabel: 'Montant',
        },
        objective: {
            label: 'Objectifs de votre demande',
            fieldType: 'select',
            options: [
                {
                    name: 'consult',
                    label: 'Uniquement une consultation avec un avocat',
                },
                {
                    name: 'info',
                    label: 'Obtenir informations juridiques pour évaluer de confier mandat à un avocat',
                },
                {
                    name: 'quick',
                    label: 'Mandater rapidement un avocat pour mon dossier',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
            autoCheckCondition: (val) => val === 'info',
        },
        otherObjective: {
            label: 'Autre objectif',

            dependsOn: {
                fieldName: 'objective',
                toBe: 'other',
            },
        },
    },
    survey: {
        recommended: {
            label: 'Recommanderiez-vous nos services de mise en relation avocats-clients?',
            fieldType: 'yesNo',
        },
        easyToUseRating: {
            label: 'Simplicité du Site et facilité d’utilisation',
            fieldType: 'rating',
        },
        speedRating: {
            label: 'Rapidité de la mise en relation',
            fieldType: 'rating',
        },
        firstCall: {
            label: 'Avez-vous eu un premier appel avec l’avocat que nous avons identifié pour vous?',
            fieldType: 'yesNo',
        },
        reasonNoFirstCall: {
            label: 'Pourquoi?',
            fieldType: 'select',
            dependsOn: {
                fieldName: 'firstCall',
                toBe: 'no',
            },
            options: [
                {
                    name: 'alreadySettled',
                    label: 'Mon dossier s’est réglé entretemps',
                },
                {
                    name: 'foundAnotherLawyer',
                    label: 'J’ai trouvé un autre avocat entretemps',
                },
                {
                    name: 'notFree',
                    label: 'Je pensais que les services de l’avocat serait gratuit',
                },
                {
                    name: 'neverCalled',
                    label: 'L’avocat n’a jamais communiqué avec moi',
                },
            ],
        },
        rightLawyer: {
            label: 'Considérez-vous que l’avocat que nous avons identifié était le bon pour vous?',
            fieldType: 'yesNoDontKnow',
        },
        reasonNotRightLawyer: {
            label: 'Pourquoi?',
            fieldType: 'select',
            dependsOn: {
                fieldName: 'rightLawyer',
                toBe: 'no',
            },
            options: [
                {
                    name: 'tooExpensive',
                    label: 'L’avocat coûtait trop cher',
                },
                {
                    name: 'notSpecializedEnough',
                    label: 'L’avocat n’était pas suffisamment spécialisé',
                },
                {
                    name: 'notEnoughExperience',
                    label: 'L’avocat manquait d’expérience',
                },
                {
                    name: 'tooFar',
                    label: 'L’avocat était trop loin géographiquement',
                },
                {
                    name: 'notAvailable',
                    label: 'L’avocat manquait de disponibilités',
                },
            ],
        },
        hiredLawyer: {
            label: 'Avez-vous confié votre dossier à l’avocat que nous avons identifié pour vous?',
            fieldType: 'buttonRow',
            options: [
                { value: 'yes', label: 'Oui' },
                { value: 'no', label: 'Non' },
                { value: 'dontKnow', label: 'Je suis toujours en réflexion' },
            ],
        },
        reasonNotHiredLawyer: {
            label: 'Pourquoi?',
            dependsOn: {
                fieldName: 'hiredLawyer',
                toBe: ['no', 'dontKnow'],
            },
        },
        happyWithLawyer: {
            label: 'Jusqu’à présent, êtes-vous satisfait de la prestation de service de l’avocat?',
            fieldType: 'yesNo',
            dependsOn: {
                fieldName: 'hiredLawyer',
                toBe: 'yes',
            },
        },
        newLawyer: {
            label: 'Souhaitez-vous que nous vous mettions en relation avec un nouvel avocat pour une deuxième soumission gratuite?',
            fieldType: 'yesNo',
            dependsOnOne: [
                {
                    fieldName: 'firstCall',
                    toBe: 'no',
                },
                {
                    fieldName: 'rightLawyer',
                    toBe: ['no', 'dontKnow'],
                },
                {
                    fieldName: 'hiredLawyer',
                    toBe: ['no', 'dontKnow'],
                },
                {
                    fieldName: 'firstCall',
                    toBe: 'no',
                },
            ],
        },
        comment: {
            label: 'Comment pourrions-nous améliorer notre service de mise en relation?',
        },
    },
    followupSurvey: {
        satisfied: {
            label: 'Êtes-vous satisfait des services rendus par l’avocat (ou les membres de son équipe) à qui nous vous avons référé?',
            fieldType: 'yesNo',
        },
        recommendsLawyer: {
            label: 'Est-ce que vous recommanderiez à un proche les services de l’avocat (ou des membres de son équipe) à qui nous vous avons référé?',
            fieldType: 'yesNo',
        },
        caseStatus: {
            label: 'Quel est l’état de votre dossier?',
            fieldType: 'select',
            options: [
                {
                    name: 'ongoing',
                    label: 'En cours',
                },
                {
                    name: 'settled',
                    label: 'Réglé',
                },
                {
                    name: 'settledUnsatisfied',
                    label: 'A été réglé de façon insatisfaisante',
                },
                {
                    name: 'other',
                    label: 'Autre',
                },
            ],
        },
        otherCaseStatus: {
            label: 'Quel est l’état de votre dossier?',
            fieldType: 'text',
            dependsOn: {
                fieldName: 'caseStatus',
                toBe: 'other',
            },
        },
        feeAdvance: {
            label: 'Est-ce que l’avocat (ou les membres de son équipe) à qui nous vous avons référé vous a demandé une avance d’honoraires pour travailler dans votre dossier?',
            fieldType: 'yesNo',
        },
        advanceAmount: {
            label: 'Si oui, quelle était le montant de l’avance?',
            fieldType: 'text',
            dependsOn: {
                fieldName: 'feeAdvance',
                toBe: 'yes',
            },
        },
        feeEstimate: {
            label: 'Est-ce que l’avocat (ou les membres de son équipe) à qui nous vous avons référé vous a donné une estimation des honoraires à payer pour travailler dans votre dossier?',
            fieldType: 'select',
            options: [
                {
                    name: 'no',
                    label: 'Non',
                },
                {
                    name: 'less-1000',
                    label: 'Moins de 1000$',
                },
                {
                    name: '1000-2499',
                    label: 'Entre 1000$ et 2499$',
                },
                {
                    name: '2500-4999',
                    label: 'Entre 2500$ et 4999$',
                },
                {
                    name: '5000-9999',
                    label: 'Entre 5000$ et 9999$',
                },
                {
                    name: '10000-19999',
                    label: 'Entre 10000$ et 19999$',
                },
                {
                    name: 'more-20000',
                    label: 'Plus de 20000$',
                },
            ],
        },
        billSubmitted: {
            label: 'Est-ce que l’avocat (ou les membres de son équipe) à qui nous vous avons référé vous a soumis une facturation?',
            fieldType: 'yesNo',
        },
        billJustified: {
            label: 'Est-ce que cette facturation vous a semblé juste?',
            fieldType: 'yesNo',
            dependsOn: {
                fieldName: 'billSubmitted',
                toBe: 'yes',
            },
        },
        billAmount: {
            label: 'Si oui, quelle était le montant de la facture?',
            fieldType: 'text',
            dependsOn: {
                fieldName: 'billSubmitted',
                toBe: 'yes',
            },
        },
    },
    rental: {
        landlordOrTenant: {
            label: 'Êtes-vous propriétaire ou locataire?',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'landlord',
                    label: 'Propriétaire',
                },
                {
                    value: 'tenant',
                    label: 'Locataire',
                },
            ],
        },
        landlord: {
            fieldType: 'group',
            fields: housingLandlord,
            dependsOn: {
                fieldName: 'landlordOrTenant',
                toBe: 'landlord',
            },
        },
        tenant: {
            fieldType: 'group',
            fields: housingTenant,
            dependsOn: {
                fieldName: 'landlordOrTenant',
                toBe: 'tenant',
            },
        },
    },
}
