export const housingLandlord = {
    buildingsNumber: {
        label: 'Il s’agit d’un immeuble de combien de logement?',
        fieldType: 'select',
        options: [
            {
                name: '1-3',
                label: '1 à 3',
            },
            {
                name: '4-6',
                label: '4 à 6',
            },
            {
                name: '7-15',
                label: '7 à 15',
            },
            {
                name: '16+',
                label: '16 et plus',
            },
        ],
        autoCheck: true,
    },
    doorsNumber: {
        label: 'Vous êtes propriétaire de combien de portes actuellement?',
        fieldType: 'select',
        options: [
            {
                name: '1-5',
                label: '1 à 5',
            },
            {
                name: '6-12',
                label: '6 à 12',
            },
            {
                name: '13-25',
                label: '13 à 25',
            },
            {
                name: '26-50',
                label: '26 à 50',
            },
            {
                name: '51+',
                label: '51 et plus',
            },
        ],
        autoCheck: true,
    },
    situation: {
        label: 'Quelle situation décrit le mieux votre dossier?',
        fieldType: 'select',
        options: [
            {
                name: 'repossessing',
                label: 'Reprise du logement',
            },
            {
                name: 'increaseRefusal',
                label: 'Refus augmentation loyer',
            },
            {
                name: 'tenantClaim',
                label: 'Réclamation du locataire',
            },
            {
                name: 'complaint',
                label: 'Plainte autres locataires',
            },
            {
                name: 'other',
                label: 'Autre',
            },
        ],
        autoCheck: true,
    },
    objective: {
        label: 'Objectifs de votre demande',
        fieldType: 'select',
        options: [
            {
                name: 'consult',
                label: 'Uniquement une consultation avec un avocat',
            },
            {
                name: 'info',
                label: 'Obtenir informations juridiques pour évaluer de confier mandat à un avocat',
            },
            {
                name: 'quick',
                label: 'Mandater rapidement un avocat pour mon dossier',
            },
            {
                name: 'other',
                label: 'Autre',
            },
        ],
        autoCheckCondition: (val) => val === 'info' || val === 'quick',
    },
    otherObjective: {
        label: 'Autre objectif',

        dependsOn: {
            fieldName: 'objective',
            toBe: 'other',
        },
    },
    formalNotice: {
        label: 'Avez-vous reçu ou transmis un avis / mise en demeure ou procédure au Tribunal administratif du logement dans ce dossier?',
        fieldType: 'yesNoDontKnow',
    },
}

export const housingTenant = {
    rentCost: {
        label: 'Quel est le montant du loyer mensuel?',
        fieldType: 'number',
    },
    job: {
        label: 'Quel est votre occupation ou emploi?',
    },
    objective: {
        label: 'Objectifs de votre demande',
        fieldType: 'select',
        options: [
            {
                name: 'consult',
                label: 'Uniquement une consultation avec un avocat',
            },
            {
                name: 'info',
                label: 'Obtenir informations juridiques pour évaluer de confier mandat à un avocat',
            },
            {
                name: 'quick',
                label: 'Mandater rapidement un avocat pour mon dossier',
            },
            {
                name: 'other',
                label: 'Autre',
            },
        ],
        autoCheckCondition: (val) => val === 'info' || val === 'quick',
    },
    otherObjective: {
        label: 'Autre objectif',

        dependsOn: {
            fieldName: 'objective',
            toBe: 'other',
        },
    },
    formalNotice: {
        label: 'Avez-vous reçu ou transmis un avis / mise en demeure ou procédure au Tribunal administratif du logement dans ce dossier?',
        fieldType: 'yesNoDontKnow',
    },
    hasCourtDate: {
        label: 'Avez-vous une date de Cour/Comparution devant le tribunal?',
        fieldType: 'yesNoDontKnow',
        autoCheckCondition: (val) => val === 'yes',
    },
    courtDate: {
        label: 'Date de cour',
        fieldType: 'date',
        dependsOn: {
            fieldName: 'hasCourtDate',
            toBe: 'yes',
        },
        autoCheck: true,
    },
}
