import React, { useState } from 'react'
import { Grid, Button } from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'
import styled from '@emotion/styled'
import { post } from '../../services/request'
import allFields, {
    englishAvailable,
    specificSteps,
} from './specificQuestions/fields'
import imgSrc from '../../../static/undraw_confirmation_2uy0.svg'
import CircularSpinner from '../../components/CircularSpinner'
import YesNoMaybe from '../../components/ButtonRow/yesNoMaybe'
import DatePicker from '../../components/DatePicker'
import TextField from '../../components/TextField'
import Select from '../../components/Select'
import { LeftSpan } from '../../components/Typo'
import ButtonRow from '../../components/ButtonRow'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import CheckboxGroup from '../../components/CheckboxGroup'
import Description from '../../containers/LandingForm/case'

const StyledRating = styled(Rating)`
    span {
        font-size: inherit;
    }
`

const SubmitButton = styled(Button)`
    margin-left: 0;
    margin-top: 20px;
    font-size: 20px;
    span {
        font-size: inherit;
    }
`

const MoreInfoForm = ({
    lawyerType,
    uuid,
    customImg,
    skip,
    submitCallback,
    withSteps,
    activeStep,
    handleNext,
    setSpecificQuestions,
    preFormOnly = false,
    postFormOnly = false,
    lang = 'fr',
}) => {
    if (!lawyerType) {
        return null
    }
    const [fields, setFields] = useState({})
    const [updated, setUpdated] = useState(false)
    const [loading, setLoading] = useState(false)
    const formDefs = allFields[lawyerType]
    const saveFieldState = (name, value, valid) => {
        setFields({
            ...fields,
            [name]: {
                value: value,
                valid: true, // change once we need a validator
            },
        })
        if (withSteps) {
            const currStep = specificSteps[lawyerType][activeStep]
            const lastFieldInStep = currStep[currStep.length - 1]
            setSpecificQuestions(prepareFieldsForSubmit())
            if (
                name === lastFieldInStep &&
                allFields[lawyerType][name].fieldType &&
                allFields[lawyerType][name].fieldType !== 'text'
            ) {
                handleNext()
            }
        }
    }

    const prepareFieldsForSubmit = () => {
        const prepFields = {}
        Object.keys(fields).forEach((f) => {
            prepFields[f] = fields[f].value
        })
        return prepFields
    }

    const submit = () => {
        const body = prepareFieldsForSubmit()
        setLoading(true)
        let url = `/meetings/${uuid}/additional-questions`
        if (lawyerType === 'survey') {
            url = `/reviews/${uuid}`
        } else if (lawyerType === 'followupSurvey') {
            url = `/followup/${uuid}`
        }

        post(url, body)
            .then((res) => {
                if (res.ok) {
                    setUpdated(true)
                    if (submitCallback) {
                        submitCallback(body)
                    }
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    let postFormQuestionsCount = 0
    if (formDefs) {
        for (let i = 0; i < Object.keys(formDefs).length; i++) {
            if (formDefs[Object.keys(formDefs)[i]].postFormOnly === true) {
                postFormQuestionsCount++
            }
        }
    }
    if (postFormOnly && postFormQuestionsCount == 0) {
        return null
    }
    return (
        <>
            {(updated || skip) && (
                <h2>
                    <Trans>Merci</Trans>!
                </h2>
            )}
            {!updated && !skip && allFields[lawyerType] && (
                <>
                    {lawyerType !== 'survey' &&
                        lawyerType !== 'followupSurvey' &&
                        !withSteps && (
                            <p>
                                <strong>
                                    <Trans i18nKey="answerTheseQuestions">
                                        Répondez à ces questions afin que nous
                                        assignions l'avocat le mieux adapté à
                                        vos besoins:
                                    </Trans>
                                </strong>
                            </p>
                        )}
                    <SpecificForm
                        lawyerType={lawyerType}
                        saveFieldState={saveFieldState}
                        withSteps={withSteps}
                        activeStep={activeStep}
                        preFormOnly={preFormOnly}
                        postFormOnly={postFormOnly}
                        {...fields}
                    />
                    {!withSteps ? (
                        <SubmitButton
                            variant="contained"
                            color="secondary"
                            onClick={submit}
                            disabled={loading}>
                            <Trans>Soumettre</Trans>
                            {loading && (
                                <CircularSpinner
                                    style={{ width: '24px', height: '24px' }}
                                />
                            )}
                        </SubmitButton>
                    ) : null}
                </>
            )}
        </>
    )
}

const validateDependency = (dependeeValue, valueToBe) => {
    if (dependeeValue === valueToBe) {
        return true
    }
    if (Array.isArray(valueToBe) && valueToBe.indexOf(dependeeValue) > -1) {
        return true
    }
    if (Array.isArray(dependeeValue) && dependeeValue.indexOf(valueToBe) > -1) {
        return true
    }
    return false
}

const checkDependency = (fieldDef, fieldsState = {}, formDefs) => {
    if (!fieldDef.dependsOn && !fieldDef.dependsOnOne) {
        return true
    }
    if (fieldDef.dependsOn) {
        const dependee = fieldsState[fieldDef.dependsOn.fieldName]
        const dependeeName = fieldDef.dependsOn.fieldName
        if (
            dependee &&
            validateDependency(dependee.value, fieldDef.dependsOn.toBe)
        ) {
            return checkDependency(
                formDefs[dependeeName],
                fieldsState,
                formDefs
            )
            //return true
        }
    }

    if (fieldDef.dependsOnOne) {
        for (var i = 0; i < fieldDef.dependsOnOne.length; i++) {
            if (
                fieldsState[fieldDef.dependsOnOne[i].fieldName] &&
                validateDependency(
                    fieldsState[fieldDef.dependsOnOne[i].fieldName].value,
                    fieldDef.dependsOnOne[i].toBe
                )
            ) {
                return true
            }
        }
    }
    return false
}

const SpecificForm = ({
    lawyerType,
    saveFieldState,
    withSteps,
    activeStep,
    endCallback = () => {},
    preFormOnly = false,
    postFormOnly = false,
    caseDescription,

    ...fieldsState
}) => {
    const { t } = useTranslation()
    const formDefs = allFields[lawyerType]

    if (formDefs) {
        if (withSteps) {
            if (activeStep >= specificSteps[lawyerType]?.length) {
                endCallback()
                return null
            }
            return (
                <Grid
                    container
                    spacing={2}
                    style={{ maxWidth: '500px', textAlign: 'left' }}>
                    {specificSteps[lawyerType][activeStep].map((f, i) => {
                        if (!preFormOnly && formDefs[f].preFormOnly) {
                            return null
                        }
                        if (postFormOnly && !formDefs[f].postFormOnly) {
                            return null
                        }
                        if (
                            !checkDependency(formDefs[f], fieldsState, formDefs)
                        ) {
                            return null
                        }

                        return (
                            <Grid item xs={12} key={f}>
                                {withSteps && i === 0 ? (
                                    <h2
                                        style={{
                                            marginBottom: '20px',
                                        }}>
                                        {t(formDefs[f].label)}{' '}
                                        <small>
                                            {t(formDefs[f].smallLabel)}
                                        </small>
                                    </h2>
                                ) : (
                                    <LeftSpan>
                                        <strong>{t(formDefs[f].label)}</strong>{' '}
                                        <small>
                                            {t(formDefs[f].smallLabel)}
                                        </small>
                                    </LeftSpan>
                                )}

                                {formDefs[f].smallLabel ? (
                                    <LeftSpan></LeftSpan>
                                ) : null}
                                {formDefs[f].fieldType ===
                                'label' ? null : f === 'caseDescription' ? (
                                    <Description
                                        saveFieldState={saveFieldState}
                                        fields={{
                                            caseDescription: caseDescription,
                                        }}
                                    />
                                ) : (
                                    <div style={{ maxWidth: '100%' }}>
                                        <Field
                                            name={f}
                                            fieldDef={formDefs[f]}
                                            fieldState={fieldsState[f]}
                                            saveFieldState={saveFieldState}
                                        />
                                    </div>
                                )}
                            </Grid>
                        )
                    })}
                </Grid>
            )
        }

        return (
            <Grid container spacing={2}>
                {Object.keys(formDefs).map((f) => {
                    if (!preFormOnly && formDefs[f].preFormOnly) {
                        return null
                    }
                    if (postFormOnly && !formDefs[f].postFormOnly) {
                        return null
                    }
                    if (!checkDependency(formDefs[f], fieldsState, formDefs)) {
                        return null
                    }
                    if (formDefs[f].fieldType === 'group') {
                        return Object.keys(formDefs[f].fields).map((g) => {
                            if (
                                !checkDependency(
                                    formDefs[f].fields[g],
                                    fieldsState,
                                    formDefs[f].fields
                                )
                            ) {
                                return null
                            }
                            return (
                                <Grid item xs={12} key={g}>
                                    <LeftSpan>
                                        {t(formDefs[f].fields[g].label)}
                                    </LeftSpan>
                                    <Field
                                        name={g}
                                        fieldDef={formDefs[f].fields[g]}
                                        fieldState={fieldsState[g]}
                                        saveFieldState={saveFieldState}
                                    />
                                </Grid>
                            )
                        })
                    }

                    return (
                        <Grid item xs={12} key={f}>
                            <LeftSpan>{t(formDefs[f].label)}</LeftSpan>
                            <Field
                                name={f}
                                fieldDef={formDefs[f]}
                                fieldState={fieldsState[f]}
                                saveFieldState={saveFieldState}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        )
    }
    return null
}

export const SpecificStepForm = {}

export const Field = ({ name, fieldDef, fieldState, saveFieldState }) => {
    const fieldProps = {
        name,
        saveFieldState,
        value: fieldState?.value,
        label: fieldDef.shortLabel,
        id: name,
        onChange: saveFieldState,
    }

    switch (fieldDef.fieldType) {
        case 'yesNoDontKnow':
            return <YesNoMaybe {...fieldProps} />
        case 'yesNo':
            return (
                <ButtonRow
                    {...fieldProps}
                    options={[
                        { value: 'yes', label: 'Oui' },
                        { value: 'no', label: 'Non' },
                    ]}
                />
            )
        case 'date':
            return (
                <DatePicker
                    {...fieldProps}
                    onChange={(date) => saveFieldState(name, date, true)}
                />
            )
        case 'select':
            return (
                <Select
                    {...fieldProps}
                    onChange={(v) => saveFieldState(name, v, true)}
                    options={fieldDef.options}
                />
            )
        case 'buttonRow':
            return <ButtonRow {...fieldProps} options={fieldDef.options} />
        case 'checkBoxes':
            return (
                <CheckboxGroup
                    {...fieldProps}
                    checkboxes={fieldDef.options}
                    initialChecked={fieldState?.value}
                    updateState={(_, value) =>
                        saveFieldState(name, value, true)
                    }
                />
            )
        case 'rating':
            return (
                <StyledRating
                    size={'large'}
                    {...fieldProps}
                    onChange={(_, value) => saveFieldState(name, value, true)}
                />
            )
        default:
            return <TextField {...fieldProps} fullWidth={true} />
    }
    return null
}

export default MoreInfoForm
