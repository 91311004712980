import urlConfig from '../utils/apiUrl'
import sessionManager, { refreshSession } from './session'
let apiUrl = urlConfig

const overrideApiURL = (url) => {
    apiUrl = url
}

const getToken = () => {
    // The session object passed to a request as a param is a React context. That context is stuck in a given time.
    // For a single request, this is not a problem. For chained requests it becomes a problem when a request
    // triggers a session refresh because subsequent requests would come with outdated sessions.
    // By using the token stored in the session manager (local storage interface) we insure to have the
    // latest token.
    return sessionManager.getSession().token
}

const getJTI = () => {
    // The session object passed to a request as a param is a React context. That context is stuck in a given time.
    // For a single request, this is not a problem. For chained requests it becomes a problem when a request
    // triggers a session refresh because subsequent requests would come with outdated sessions.
    // By using the token stored in the session manager (local storage interface) we insure to have the
    // latest token.
    return sessionManager.getSession().jti
}

// DEPRECATED
export function securedPost(endpoint, bodyObject, session) {
    return new Promise((resolve, reject) => {
        postRequest(endpoint, bodyObject, session)
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                console.log('error ', err)
                reject(err)
            })
    })
}

function postRequest(endpoint, bodyObject, session, refreshAttempted = false) {
    return new Promise((resolve, reject) => {
        const token = getToken()
        const headers = token
            ? {
                  'Content-Type': 'application/json',
                  Authorization: token,
              }
            : {}

        fetch(`//${apiUrl}${endpoint}`, {
            method: 'POST',
            redirect: 'follow',
            mode: 'cors',
            credentials: 'include',
            headers,
            body:
                typeof bodyObject === 'object'
                    ? JSON.stringify(bodyObject)
                    : bodyObject,
        })
            .then((res) => {
                if (res.status === 401 && !refreshAttempted) {
                    refreshSession().then((refreshedSession) => {
                        if (!refreshedSession) {
                            reject('Could not refresh')
                            return
                        }
                        resolve(
                            postRequest(
                                endpoint,
                                bodyObject,
                                refreshedSession,
                                true
                            )
                        )
                    })
                    return
                }
                const contentType = res.headers.get('Content-Type')
                if (contentType === 'application/octet-stream') {
                    res.blob().then((blob) => {
                        var file = window.URL.createObjectURL(blob)
                        var a = document.createElement('a')
                        a.href = file
                        a.download = `document.pdf`
                        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click()
                        a.remove()
                        resolve(res)
                    })
                } else {
                    res.json()
                        .then((json) => {
                            if (json) {
                                if (json.token) {
                                    session.saveSession(json.token)
                                }
                                res.parsedJSON = json
                            }
                            resolve(res)
                        })
                        .catch((err) => {
                            res.parsedJSON = undefined
                            resolve(res)
                        })
                }
            })
            .catch((err) => {
                console.log('error ', err)
                reject(err)
            })
    })
}

function deleteRequest(endpoint, session, refreshAttempted = false) {
    return new Promise((resolve, reject) => {
        const token = getToken()
        const headers = token
            ? {
                  Authorization: token,
              }
            : {}
        fetch(`//${apiUrl}${endpoint}`, {
            method: 'DELETE',
            redirect: 'follow',
            mode: 'cors',
            credentials: 'include',
            headers,
        })
            .then((res) => {
                if (res.status === 200) {
                    resolve(res)
                } else if (
                    (res.status === 401 || res.status === 419) &&
                    !refreshAttempted
                ) {
                    refreshSession().then((refreshedSession) => {
                        if (!refreshedSession) {
                            reject('Could not refresh')
                            return
                        }
                        resolve(getRequest(endpoint, refreshedSession, 1))
                    })
                } else {
                    resolve(res)
                }
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// DEPRECATED
export function securedGet(endpoint, session) {
    return new Promise((resolve, reject) => {
        getRequest(endpoint, session)
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

function getRequest(endpoint, session, refreshAttempted = false) {
    return new Promise((resolve, reject) => {
        if (typeof window === 'undefined') {
            resolve({ ok: false })
            return
        }
        const token = getToken()
        const headers = token
            ? {
                  Authorization: token,
              }
            : {}
        fetch(`//${apiUrl}${endpoint}`, {
            method: 'GET',
            mode: 'cors',
            redirect: 'follow',
            credentials: 'include',
            headers,
        })
            .then((res) => {
                if (res.status === 200) {
                    res.json()
                        .then((json) => {
                            if (json) {
                                if (json.token) {
                                    session.saveSession(json.token)
                                }
                                res.parsedJSON = json
                            }
                            resolve(res)
                        })
                        .catch((err) => {
                            reject(err)
                        })
                } else if (
                    (res.status === 401 || res.status === 419) &&
                    !refreshAttempted
                ) {
                    refreshSession().then((refreshedSession) => {
                        if (!refreshedSession) {
                            reject('Could not refresh')
                            return
                        }
                        resolve(getRequest(endpoint, refreshedSession, 1))
                    })
                } else {
                    reject(res.status)
                }
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export const get = getRequest
export const post = postRequest
export const delet = deleteRequest
export const override = overrideApiURL
